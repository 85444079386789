
export const en = {
  page: {
    lobby: 'Lobby',
    hall: 'Exhibition Hall',
    webinar: 'Webinar',
    products: 'Search by Products',
    custom: 'How to utilize virtual expo?',
  },
};

export const ja = {
  page: {
    lobby: 'エントランス',
    hall: '展示会場',
    products: '製品から探す',
    custom: 'オンライン来場・商談サービスの使い方',
  },
};
