import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectLocale } from 'models/localization';

declare global {
  interface Window {
    $zopim?: any;
  }
}

const ReduxLocaleObserver = () => {
  const locale = useSelector(selectLocale);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language !== locale) setTimeout(() => i18n.changeLanguage(locale), 0);
    const { $zopim } = window;
    if (!$zopim) return;
    $zopim(() => {
      $zopim.livechat.setLanguage(locale);
    });
  }, [locale, i18n]);

  return null;
};

export default ReduxLocaleObserver;
