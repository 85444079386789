export const CommonLocalizableString = `
  fragment CommonLocalizableString on LocalizableString {
    values(locales: $locales) {
      locale
      value
    }
  }
`;

export const CommonLocalizableImage = `
  fragment CommonLocalizableImage on LocalizableImage {
    values(locales: $locales) {
      locale
      value {
        originalImageUrl
      }
    }
  }
`;

export const CommonLocalizableVideo = `
  fragment CommonLocalizableVideo on LocalizableVideo {
    values(locales: $locales) {
      locale
      value {
        playbackUrl
      }
    }
  }
`;

export const CommonLocalizableFile = `
  fragment CommonLocalizableFile on LocalizableFile {
    values(locales: $locales) {
      locale
      value {
        fileUrl
        filename
      }
    }
  }
`;

export const NonQuestionContentElement = `
  fragment NonQuestionContentElement on BoothContentElement {
    __typename
    ... on BoothRichtextContentElement {
      contentRichtextHtml {
        ...CommonLocalizableString
      }
    }
    ... on BoothImageContentElement {
      image {
        ...CommonLocalizableImage
      }
    }
    ... on BoothVideoContentElement  {
      videoFile {
        ...CommonLocalizableVideo
      }
    }
    ... on BoothFileContentElement {
      file {
        ...CommonLocalizableFile
      }
    }
  }
`;

export const CommonBoothTier = `
  fragment CommonBoothTier on BoothTier {
    __typename
    name {
      ...CommonLocalizableString
    }
  }
`;

export const CommonBooth = `
  fragment CommonBooth on Booth {
    id
    appliedDesignCodename
    appointmentBookingUrl
    meetupEnabled
    website
    boothNumber
    description {
      ...CommonLocalizableString
    }
    name {
      ...CommonLocalizableString
    }
    logo {
      ...CommonLocalizableImage
    }
    tier {
      ...CommonBoothTier
    }
    categories {
      nodes {
        id
        name {
          ...CommonLocalizableString
        }
      }
    }
    designElements {
      nodes {
        id
        name
        kind
        color
        image {
          ...CommonLocalizableImage
        }
        video {
          ...CommonLocalizableVideo
        }
        contentRichtextHtml {
          ...CommonLocalizableString
        }
        thumbnailImage {
          ...CommonLocalizableImage
        }
        pdf {
          ...CommonLocalizableFile
        }
      }
    }
    rootContentElement {
      tabs {
        nodes {
          title {
            ...CommonLocalizableString
          }
          body {
            nodes {
              __typename
              id
              ...NonQuestionContentElement
              ... on BoothQuestionContentElement {
                question {
                  ...CommonLocalizableString
                }
                answer {
                  ...NonQuestionContentElement
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const PreviewBooth = `
  fragment PreviewBooth on Booth {
    id
    website
    appliedDesignCodename
    description {
      ...CommonLocalizableString
    }
    name {
      ...CommonLocalizableString
    }
    logo {
      ...CommonLocalizableImage
    }
    categories {
      nodes {
        id
        name {
          ...CommonLocalizableString
        }
      }
    }
    designElements {
      nodes {
        id
        name
        kind
        color
        image {
          ...CommonLocalizableImage
        }
        video {
          ...CommonLocalizableVideo
        }
        contentRichtextHtml {
          ...CommonLocalizableString
        }
        thumbnailImage {
          ...CommonLocalizableImage
        }
        pdf {
          ...CommonLocalizableFile
        }
      }
    }
  }
`;
