import React, {
  useState,
  useRef,
  useContext,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import IconButton from '@material-ui/core/IconButton';
import {
  APP,
  EVENT_TOKEN,
  RONGCLOUD_CHATROOM_PREFIX,
} from 'appenv';
import pushChatRoomNotification from 'network/pushChatRoomNotification';
import uploadFileMutation from 'network/uploadFile';
import addContact from 'network/addContact';
import FileActiveIcon from 'assets/fileActiveIcon.svg';
import ContactCardIcon from 'assets/contactCard.svg';

import { UserSessionContext } from '../UserSession';
import SendIcon from '../icons/Send';

const RootContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #F8F8F8;
  padding: 0 8px;
`;

const StyledFileInput = styled.input`
  display: none;
`;

const StyledTextInput = styled.input`
  background: rgba(255, 255, 255, 0.5);
  outline: 0;
  border: 1px solid #DBDEE2;
  padding: 0 6px;
  border-radius: 15px;
  margin: 10px 0;
  height: 33px;
  width: calc(100% - 50px);
  box-sizing: border-box;
  font-size: 16px;
`;

const SendButton = styled(SendIcon)`
  transition: color 400ms;
`;

const StyledFileIcon = styled.img`
  height: 24px;
  width: 24px;
`;

const StyledContactIcon = styled.img`
  height: 24px;
  width: 24px;
`;

const EnterBtnCode = 13;

const enableContacts = () => (APP === 'xtra');

const ChatMessageInputField = ({
  joiningChat,
  sendMessage,
  setAlertOpen,
  file,
  setFile,
  hasFile,
  fileInputRef,
  setIsUploading,
  setHasError,
}) => {
  const { userSession } = useContext(UserSessionContext);
  const [messageValue, setMessageValue] = useState('');
  const textInputRef = useRef();
  const { targetId, type, roomName } = useSelector((state) => state.chatTarget);
  const [upload] = useMutation(uploadFileMutation);

  const getTrackId = () => {
    let trackId = targetId;
    if (targetId === `${RONGCLOUD_CHATROOM_PREFIX}_hall`) {
      trackId = 'Hall';
    } else if (targetId === `${RONGCLOUD_CHATROOM_PREFIX}_announcement`) {
      trackId = 'Announcement';
    } else {
      const result = targetId.split(`${RONGCLOUD_CHATROOM_PREFIX}_booth_`);
      const boothId = result[1];
      if (boothId) {
        trackId = boothId;
      }
    }
    return trackId;
  };

  const handleMessageSend = useCallback(async () => {
    let conversationType = '';
    switch (type) {
      case 'group':
        conversationType = window.RongIMLib.ConversationType.GROUP;
        break;
      case 'private':
        conversationType = window.RongIMLib.ConversationType.PRIVATE;
        break;
      case 'system':
        conversationType = window.RongIMLib.ConversationType.SYSTEM;
        break;
      default:
        break;
    }

    if (messageValue) {
      const message = messageValue;
      setMessageValue('');
      if (type === 'system') {
        const success = await pushChatRoomNotification(userSession.authToken, message);
        setAlertOpen(success);
      } else {
        sendMessage(targetId, conversationType, messageValue, 'TextMessage');
        if (type === 'private') {
          window.trackingEvent('TextMessage_Send', '1 on 1 Chatroom', userSession.email);
        } else {
          const trackingId = getTrackId();
          window.trackingEvent('TextMessage_Send', 'Public Chatroom', `${trackingId} - ${roomName}`);
        }
      }
      textInputRef.current.focus();
    }
    if (hasFile && type !== 'system') {
      setIsUploading(true);
      setHasError(false);
      upload({
        variables: {
          eventToken: EVENT_TOKEN,
          file,
          uniqueToken: userSession.authToken,
        },
      }).then((res) => {
        setIsUploading(false);
        const uploadedFile = {
          name: file.name,
          size: file.size,
          type: file.type,
          fileUrl: res.data.uploadFile.attachment.fileUrl,
        };
        sendMessage(targetId, conversationType, uploadedFile, 'FileMessage');
        if (type === 'private') {
          window.trackingEvent('FileUpload_Send', '1 on 1 Chatroom', userSession.email);
        } else {
          const trackingId = getTrackId();
          window.trackingEvent('FileUpload_Send', 'Public Chatroom', `${trackingId} - ${roomName}`);
        }
        fileInputRef.current.value = null;
        setFile({});
      }).catch((error) => {
        setHasError(true);
        setIsUploading(false);
        console.log(error);
      }, [file]);
    }
  }, [messageValue, sendMessage, file]);

  const handleShareContactCard = useCallback(async () => {
    let conversationType = '';
    switch (type) {
      case 'group':
        conversationType = window.RongIMLib.ConversationType.GROUP;
        break;
      case 'private':
        conversationType = window.RongIMLib.ConversationType.PRIVATE;
        break;
      default:
        break;
    }
    sendMessage(targetId, conversationType, '[Contact card]', 'ContactCardMessage');
  }, [sendMessage]);

  const onKeydown = useCallback((event) => {
    switch (event.keyCode) {
      case EnterBtnCode:
        handleMessageSend();
        break;
      default:
    }
  }, [handleMessageSend]);

  const disableSendBtn = !messageValue && !hasFile;

  return (
    <RootContainer>
      <StyledFileInput
        ref={fileInputRef}
        onChange={(e) => setFile(e.target.files[0])}
        type="file"
        accept=".pdf"
      />
      {
        type !== 'system' && APP !== 'hkstp608' && (
          <IconButton onClick={() => fileInputRef.current.click()}>
            <StyledFileIcon src={FileActiveIcon} alt="upload file" />
          </IconButton>
        )
      }
      {
        enableContacts() && (
          <IconButton onClick={handleShareContactCard}>
            <StyledContactIcon src={ContactCardIcon} alt="share contact card" />
          </IconButton>
        )
      }
      <StyledTextInput
        ref={textInputRef}
        disabled={joiningChat}
        value={messageValue}
        onChange={(e) => setMessageValue(e.target.value)}
        onKeyDown={onKeydown}
      />
      <IconButton
        disabled={disableSendBtn}
        onClick={handleMessageSend}
      >
        <SendButton
          height={24}
          width={24}
          color={disableSendBtn ? '#A2AAB3' : '#5C8DF3'}
        />
      </IconButton>
    </RootContainer>
  );
};

ChatMessageInputField.propTypes = {
  joiningChat: PropTypes.bool.isRequired,
  sendMessage: PropTypes.func.isRequired,
  setAlertOpen: PropTypes.func.isRequired,
  file: PropTypes.shape().isRequired,
  setFile: PropTypes.func.isRequired,
  hasFile: PropTypes.bool.isRequired,
  fileInputRef: PropTypes.shape().isRequired,
  setIsUploading: PropTypes.func.isRequired,
  setHasError: PropTypes.func.isRequired,
};

export default ChatMessageInputField;
