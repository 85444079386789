import React, { useEffect, useCallback, useMemo } from 'react';
import {
  PAGE_NAMES,
  BRAND_COLOR,
} from 'appenv';
import { useSelector, useDispatch } from 'react-redux';
import { selectLocale } from 'models/localization';
import { fetchProducts, fetchProductsByCategory } from 'models/products';
import styled from 'styled-components';
import { ProductCategory } from 'models/types/productCategories';
import {
  uncheckCategory,
  checkCategory,
  selectCheckedProductIds,
  uncheckAllCategories,
} from 'models/productCategories/selected';
import { useTranslation } from 'react-i18next';
import Routes from 'Routes';
import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import { selectAllProductCategories, fetchProductCategories } from 'models/productCategories/categories';
import XtraBreadcrumbs from 'components/XtraBreadcrumbs';
import findAvailableLocale from 'models/helpers/findAvailableLocale';
import FilterCheckboxOption from './FilterCheckboxOption';

const HallFilterContainer = styled.div`
  width: 300px;
  height: 100%;
  overflow: auto;
  padding: 24px;
  color: #282B40;
  background: rgba(255, 255, 255, 0.8);

  @media only screen and (max-width: 959px) {
    display: none;
  }
`;

const FilterTitleContainer = styled.div`
  width: 100%;
`;

const FilterText = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  padding: 12px 0;
  display: inline-block;
  text-transform: uppercase;
`;

const ClearAllText = styled.span`
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.4px;
  cursor: pointer;
  transition: all 0.1s;
  display: block;
  padding: 12px 0;
  float: right;

  &:hover {
    color: ${BRAND_COLOR || '#0CA58B'};
  }
`;

const FilterWrapper = styled.div`
  display: block;
`;

const StyledXtraBreadcrumbs = styled(XtraBreadcrumbs)`
  padding: 0;
`;

const CategoryLabel = styled.span`
  font-weight: bold;
  margin-left: 8px;
`;

const IndentedCheckbox = styled.div`
  margin-left: 20px;
`;

const ProductCategoriesFilter = ({
  categories,
  selectedCategories,
  onCategorySelect,
}: {
  categories: ProductCategory[];
  selectedCategories: any;
  onCategorySelect: (categoryId: string, checked: boolean) => void;
}) => {
  const locale = useSelector(selectLocale);
  return (
    <>
      {categories.map((category, index) => {
        if (category.subcategories && Array.isArray(category.subcategories)) {
          return (
            <>
              <CategoryLabel>{findAvailableLocale(category.label, locale)}</CategoryLabel>
              <IndentedCheckbox>
                <ProductCategoriesFilter
                  key={category.id}
                  categories={category.subcategories}
                  selectedCategories={selectedCategories}
                  onCategorySelect={onCategorySelect}
                />
              </IndentedCheckbox>
            </>
          );
        }

        return (
          <FilterCheckboxOption
            index={index}
            label={findAvailableLocale(category.label, locale)}
            category={category.id}
            key={category.id}
            checked={selectedCategories[category.id]}
            onClick={onCategorySelect}
          />
        );
      })}
    </>
  );
};

const ProductFilter = () => {
  const { t } = useTranslation();
  const categoriesFetched = useSelector((state) => (state as any).productCategories.categories.fetched as boolean);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!categoriesFetched) {
      dispatch(fetchProductCategories());
    }
  }, [dispatch, categoriesFetched]);

  const productCategories = useSelector(selectAllProductCategories);

  const resetCategories = useCallback(() => {
    dispatch(uncheckAllCategories());
    dispatch(fetchProducts());
  }, [dispatch]);

  const selectedProductCategories = useSelector(selectCheckedProductIds);
  const selectedProductCategoriesMap = useMemo(() => selectedProductCategories.reduce(
    (acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {},
  ), [selectedProductCategories]);

  useEffect(() => {
    if (selectedProductCategories.length) {
      dispatch(fetchProductsByCategory({
        categories: selectedProductCategoriesMap,
      }));
    } else {
      dispatch(fetchProducts());
    }
  }, [dispatch, resetCategories, selectedProductCategories.length, selectedProductCategoriesMap]);

  const onCategorySelect = useCallback((categoryId?: string, checked?: boolean) => {
    dispatch(checked ? checkCategory(categoryId) : uncheckCategory(categoryId));
  }, [dispatch]);

  const fetchingCategories = useSelector((state) => (state as any).productCategories.categories.fetching as boolean);
  useEffect(() => {
    if (!fetchingCategories) {
      onCategorySelect();
    }
  }, [fetchingCategories, onCategorySelect]);

  return (
    <HallFilterContainer>
      <StyledXtraBreadcrumbs paths={[{ name: PAGE_NAMES.lobby || t('page.lobby', 'Lobby'), to: Routes.lobby }, { name: PAGE_NAMES.hall || t('page.hall', 'Exhibition Hall') }]} />
      <FilterTitleContainer>
        <FilterText>
          {`${t('filter.title', 'Filter')} ${selectedProductCategories.length ? `(${selectedProductCategories.length})` : ''}`}
        </FilterText>
        <ClearAllText onClick={resetCategories}>
          {t('filter.clear_all', 'Clear all')}
        </ClearAllText>
      </FilterTitleContainer>
      {fetchingCategories ? (
        <CircularLoadingIndicator />
      ) : (
        <FilterWrapper>
          <ProductCategoriesFilter
            categories={productCategories}
            onCategorySelect={onCategorySelect}
            selectedCategories={selectedProductCategoriesMap}
          />
        </FilterWrapper>
      )}
    </HallFilterContainer>
  );
};

export default ProductFilter;
