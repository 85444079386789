import React, {
  useMemo,
  useEffect,
} from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { ENABLED_FEATURES } from 'appenv';
import { areEqual } from 'react-window';

import Footer from 'components/footer/Footer';
import { fetchMoreMetaBooths, selectBoothPageInfo } from 'models/cms/booths';
import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import BoothPreview from '../BoothPreview';
import HeaderComponent from './HeaderComponent';
import FooterWrapper from './FooterWrapper';

const ExhibitorRowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  border-radius: 0 !important;
`;

const BoothPreviewRow = styled.div`
  display: flex;
  border-radius: 0 !important;
  flex-direction: row;
`;

export const CMSFetchMoreFooter = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMoreMetaBooths());
  }, [dispatch]);

  return null;
};

export const CMSFetchMoreIndicator = ({ offsetTop }: any) => {
  const pageInfo = useSelector(selectBoothPageInfo);
  if (!pageInfo.fetching) return null;
  return (
    <FooterWrapper offsetTop={offsetTop}>
      <CircularLoadingIndicator />
    </FooterWrapper>
  );
};

interface RowProps {
  index: number;
  data: Record<any, any>[];
  itemsPerRow: number;
  rowCount: number;
  style: Record<string, any>;
  isFiltered: boolean;
  isDesktop: boolean;
  containerHeight: number;
  headerOffset: number;
  hasFilter: boolean;
}

const ListRow = ({
  index,
  data,
  itemsPerRow,
  style,
  rowCount,
  isDesktop,
  headerOffset,
  containerHeight,
  hasFilter,
  isFiltered,
}: RowProps) => {
  const firstIndex = useMemo(() => (index * itemsPerRow), [index, itemsPerRow]);
  const exhibitorRow = useMemo(() => {
    const lastIndex = firstIndex + itemsPerRow;
    const rowData = [];

    for (let i = firstIndex; i < lastIndex; i += 1) {
      if (i >= data.length) break;
      rowData.push(data[i]);
    }
    return rowData;
  }, [data, itemsPerRow, firstIndex]);

  const difference = useMemo(() => {
    const footerTop = rowCount * style.height + headerOffset;
    return Math.max(0, containerHeight - footerTop - 90);
  }, [containerHeight, headerOffset, rowCount, style.height]);

  return (
    <ExhibitorRowContainer style={{
      ...style,
      top: `${parseFloat(style.top) + headerOffset}px`,
    }}
    >
      {(index === 0) && (
        <HeaderComponent
          isDesktop={isDesktop}
          hasFilter={hasFilter}
          isFiltered={isFiltered}
          filterLength={data.length}
          headerOffset={headerOffset}
        />
      )}
      <BoothPreviewRow>
        {exhibitorRow.map((booth) => (
          <BoothPreview
            key={booth.id}
            booth={booth}
          />
        ))}
      </BoothPreviewRow>
      {ENABLED_FEATURES.xtraCMS && (index >= rowCount - 2) && (
        <CMSFetchMoreFooter />
      )}
      {ENABLED_FEATURES.xtraCMS && (index === rowCount - 1) && (
        <CMSFetchMoreIndicator offsetTop={difference} />
      )}
      {(index === rowCount - 1) && (
        <FooterWrapper offsetTop={difference}>
          <Footer hashState="/hall" />
        </FooterWrapper>
      )}
    </ExhibitorRowContainer>
  );
};

export default React.memo(ListRow, areEqual);
