const bannerUtils = {
  toImage: (src: string, href = null) => ({
    src,
    href,
    type: 'image',
  }),
};

// eslint-disable-next-line import/prefer-default-export
export const ja = {
  entry: {
    to_exhibition_hall: '展示会場はこちら',
    how_to_use_oms: '<b>はじめに</b><i>オンライン来場・商談サービスの使い方</i>',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/bb022728-ac20-4f70-815d-3fb517a63fe3.png',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/bb022728-ac20-4f70-815d-3fb517a63fe3.png',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/bb022728-ac20-4f70-815d-3fb517a63fe3.png',
        'https://www.office-expo.jp/ja-jp.html',
      ),
    ],
    mobile: [],
  },
};

ja.banners.mobile = [
  ...ja.banners.middle,
];
