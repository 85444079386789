import React, { useState, useEffect, useContext } from 'react';
import { BRAND_COLOR, MULTITRACKS_IDS } from 'appenv';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';
import HtmlParser from 'react-html-parser';
import { WebinarPropType } from 'models/types/webinars';
import { getWebinarTimeRange } from 'utils/dateFormatters';
import { WebinarType } from 'models/webinars';
import { ButtonIcon, RoundedButtonLink } from 'components/input/RoundedButton';
import { envSwitch } from 'utils/envUtils';
import Apps from 'apps';
import { UserSessionContext } from 'components/UserSession';
import WebinarSpeakers from './WebinarSpeakers';
import WebinarMedia from './WebinarMedia';
import Video from '../../components/icons/Video';
import WebinarTrackTag from './WebinarTrackTag';

const locale = 'en';

const WebinarTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 24px;
  color: #484848;
`;

const WebinarTime = styled.div`
  font-size: 14px;
  color: #A2AAB3;
`;

const ContentContainer = styled.div`
  flex: 1 1;
`;

const WebinarDetails = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #484848;
`;

const WatchNowButtonFrame = styled.div`
  width: 100%;
  bottom: 0;
  left: 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  @media (max-width: 959px) {
    position: fixed;
    padding: 10px 16px;
    z-index: 1;
    border-top: 1px solid rgba(162, 170, 179, 0.3);
    background: white;
  }
`;

const WatchNowButton = styled(RoundedButtonLink)`
  height: 40px;
  background: ${BRAND_COLOR || '#FA582D'};
  padding: 0 15px 0 10px;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  text-transform: uppercase;
  opacity: ${({ disabled }) => (disabled ? '0.4' : 'initial')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  & path {
    fill: white;
  }

  & ${ButtonIcon} {
    padding: 0 6px;
    flex-basis: auto;
  }

  @media (min-width: 960px) {
    width: 180px;
    margin-top: 26px;
  }
`;

const WebinarExtraInfoWrapper = styled.div`
  @media (max-width: 960px) {
    padding: 16px;
    padding-bottom: 64px;
  }
`;

const WebinarMainContentContainer = ({ webinar }) => {
  const { t } = useTranslation();
  const webinarTimeRange = !webinar.startAt ? null : getWebinarTimeRange(webinar.startAt, webinar.endAt);
  const { startAt, endAt } = webinar;
  const { userSession } = useContext(UserSessionContext);

  const [isComingSoon, setIsComingSoon] = useState(true);
  const [isEnded, setIsEnded] = useState(false);
  const [isLive, setIsLive] = useState(false);

  useEffect(() => {
    const updateButton = () => {
      const currentTime = moment();
      setIsComingSoon(currentTime.isBefore(startAt));
      setIsEnded(currentTime.isAfter(endAt));
      setIsLive(!currentTime.isBefore(startAt) && !currentTime.isAfter(endAt));
    };
    updateButton();
    const taskId = setInterval(() => updateButton(), 1000);
    return () => clearInterval(taskId);
  }, [startAt, endAt]);

  const clickWatchNowButton = () => {
    window.trackingEvent('MainMenu_Webinar', 'PlayWebinarVideo', `Webinar-${webinar.id}-${webinar?.translations[locale].title}_${webinar.url || ''}`);
    window.trackingEvent('MainMenu_Webinar', `PlayWebinar_by_${userSession.email}`, `Webinar-${webinar.id}-${webinar?.translations[locale].title}_${webinar.url || ''}`);
  };

  const forceShow = envSwitch([
    [[Apps.SCMP711], true],
  ], false);

  return (
    <ContentContainer>
      {(webinar.kind !== WebinarType.Live || forceShow) && <WebinarMedia webinar={webinar} />}
      <WebinarExtraInfoWrapper>
        <WebinarTitle>{webinar.translations[locale].title}</WebinarTitle>
        <WebinarTime>
          {webinarTimeRange && t(
            'webinar.datetime_format',
            `${webinarTimeRange.from} - ${webinarTimeRange.to} (${webinarTimeRange.length}mins) ${webinarTimeRange.date}`,
            {
              startTime: webinarTimeRange.from,
              endTime: webinarTimeRange.to,
              duration: webinarTimeRange.length,
              timezone: webinarTimeRange.timezone,
              date: webinarTimeRange.date,
            },
          )}
        </WebinarTime>
        {webinar.track && MULTITRACKS_IDS.indexOf(webinar.track.id) !== -1 && <WebinarTrackTag track={webinar.track} />}
        {!forceShow && webinar.kind === WebinarType.Live && (webinar.translations[locale].url?.trim()?.length ?? 0) > 0 && (
          <WatchNowButtonFrame disabled={isEnded || isComingSoon}>
            <WatchNowButton
              kind={webinar.kind}
              onClick={isLive ? clickWatchNowButton : undefined}
              href={isLive ? webinar.translations[locale].url : undefined}
              target="_blank"
              disabled={isEnded || isComingSoon}
            >
              <ButtonIcon><Video /></ButtonIcon>
              {isLive && t('webinar.session.live', 'Watch Now')}
              {isEnded && t('webinar.session.ended', 'Session Ended')}
              {isComingSoon && t('webinar.session.coming_soon', 'Coming Soon')}
            </WatchNowButton>
          </WatchNowButtonFrame>
        )}

        <WebinarDetails>
          {HtmlParser(webinar.translations[locale].description)}
        </WebinarDetails>
        {webinar.speakers && webinar.speakers.length > 0 && <WebinarSpeakers webinar={webinar} />}
      </WebinarExtraInfoWrapper>
    </ContentContainer>
  );
};

WebinarMainContentContainer.propTypes = { webinar: WebinarPropType.isRequired };

export default WebinarMainContentContainer;
