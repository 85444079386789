import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { APP, PAGE_NAMES, ENABLED_FEATURES } from 'appenv';

import { useSelector } from 'react-redux';
import { UserSessionContext } from 'components/UserSession';
import { searchResultsSelector } from 'models/searchResults';

import ExhibitorSearchResultItem from 'components/search/ExhibitorSearchResultItem';
import WebinarSearchResultItem from 'components/search/WebinarSearchResultItem';
import ParticipantSearchResultItem from 'components/search/ParticipantSearchResultItem';
import EmptyStatePlaceholder from 'components/search/EmptyStatePlaceholder';

const RootContainer = styled.div`
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ListContainer = styled.div`
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`;

const StyledSubHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #999;
  margin: 8px 16px;
`;

const ViewAllResultsItem = withStyles(() => ({
  root: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#5C8DF3',
    textAlign: 'center',
    paddingTop: '13px',
    paddingBottom: '13px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F6F6F6',
    flex: '0 0 auto',
    width: '100%',
  },
}))(MenuItem);

const pickFromExhibitor = (exhibitor, locale) => {
  if (ENABLED_FEATURES.xtraCMS) {
    const target = exhibitor.appliedDesignCodename === '002'
      ? 'middle_banner'
      : 'top_banner';

    const logo = exhibitor.logo?.[locale]?.originalImageUrl
      || (exhibitor.designElements || [])
        .find((node) => node.name === target)
        ?.image?.[locale]?.originalImageUrl;

    return {
      name: exhibitor.name[locale],
      logo,
      description: '',
    };
  }

  const { name, description } = exhibitor.translations[locale] || {};
  const logoThumbs = exhibitor.logo?.data?.thumbnails;
  const bestLogoThumb = logoThumbs?.find((thumb) => thumb?.url?.endsWith('?key=directus-medium-contain'));
  return { name, description, logo: bestLogoThumb?.url };
};

const SearchResultList = ({
  navigateToAllResults,
}) => {
  const locale = 'en';
  const {
    exhibitors,
    webinars,
    participants,
  } = useSelector(searchResultsSelector);
  const { canSee } = useContext(UserSessionContext);

  const canSeeExhibitors = canSee('hall');
  const showExhibitors = exhibitors.length > 0 && canSeeExhibitors;
  const canSeeWebinars = canSee('webinar') && APP !== 'ms624';
  const showWebinars = webinars.length > 0 && canSeeWebinars;
  const canSeeParticipants = canSee('participant');
  const showParticipants = participants.length > 0 && canSeeParticipants;
  const showResults = showExhibitors || showWebinars || showParticipants;

  return (
    <RootContainer>
      {
        showResults ? (
          <>
            <ListContainer>
              {
                showExhibitors && (
                  <>
                    <StyledSubHeader>
                      {PAGE_NAMES.exhibitors}
                    </StyledSubHeader>
                    {
                      exhibitors.map((exhibitor) => {
                        const { name, logo, description } = pickFromExhibitor(exhibitor, locale);

                        return (
                          <ExhibitorSearchResultItem
                            key={exhibitor.id}
                            id={`${exhibitor.id}`}
                            name={name}
                            logo={logo}
                            description={description}
                            variant="compact"
                          />
                        );
                      })
                    }
                  </>
                )
              }
              {
                showWebinars && (
                  <>
                    <StyledSubHeader>
                      {PAGE_NAMES.webinar}
                    </StyledSubHeader>
                    {
                      webinars.map(({
                        id,
                        kind,
                        startAt,
                        endAt,
                        timezone,
                        translations,
                      }) => {
                        const { title } = translations[locale] || {};
                        return (
                          <WebinarSearchResultItem
                            key={id}
                            type={kind}
                            id={`${id}`}
                            name={title}
                            startAt={startAt}
                            endAt={endAt}
                            timezone={timezone}
                            variant="compact"
                          />
                        );
                      })
                    }
                  </>
                )
              }
              {
                showParticipants && (
                  <>
                    <StyledSubHeader>
                      {PAGE_NAMES.participant}
                    </StyledSubHeader>
                    {
                      participants.map((participant) => {
                        const {
                          name,
                          uuid,
                          customAttendeeFields: {
                            vexpo_chat_tag: userTag,
                            vexpo_chat_user_description: userDescription,
                          },
                        } = participant;
                        return (
                          <ParticipantSearchResultItem
                            key={uuid}
                            uuid={uuid}
                            name={name}
                            userTag={userTag}
                            userDescription={userDescription}
                            variant="compact"
                          />
                        );
                      })
                    }
                  </>
                )
              }
            </ListContainer>
            <ViewAllResultsItem onClick={navigateToAllResults}>
              View all search results
            </ViewAllResultsItem>
          </>
        ) : (
          <EmptyStatePlaceholder />
        )
      }
    </RootContainer>
  );
};

SearchResultList.propTypes = {
  navigateToAllResults: PropTypes.func,
};

SearchResultList.defaultProps = {
  navigateToAllResults: () => { },
};

export default SearchResultList;
