import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { selectConversationById, sortConversationsBySentTime } from 'models/conversations';
import { RONGCLOUD_CHATROOM_PREFIX } from 'appenv';
import ChatListPlaceholder from 'assets/chatListPlaceholder.svg';

import CollapsibleChatGroupView711 from './CollapsibleChatGroupView711';
import ChatConversationListItem711 from './ChatConversationListItem711';

const ChatListPlaceholderContainer = styled.div`
  text-align: center;
  padding-top: 120px;
`;

const StyledImg = styled.img`
  max-height: 100px;
  max-width: 100px;
`;

const StyledBoldTitle = styled.div`
  font-weight: 900;
  font-size: 18px;
  color: #484848;
  margin: 24px 0 16px 0;
`;

const StyledText = styled.div`
  margin: 0 34px;
  color: #484848;
  font-size: 14px;
  font-weight: normal;
`;

const ChatConversationList711 = ({
  setChatTarget,
  userRole,
}) => {
  const announcement = useSelector((state) => (selectConversationById(state, `${RONGCLOUD_CHATROOM_PREFIX}_announcement`))) || {};
  const conversations = useSelector(sortConversationsBySentTime());
  const privateChat = conversations.filter((chat) => (chat.conversationType === 'private'));
  const groupChat = conversations.filter((chat) => (chat.conversationType === 'group'));

  return (
    <>
      {
        (isEmpty(announcement) && !groupChat.length && !privateChat.length) ? (
          <ChatListPlaceholderContainer>
            <StyledImg src={ChatListPlaceholder} alt="Chat list is empty" />
            <StyledBoldTitle>No messages yet</StyledBoldTitle>
            <StyledText>Visit any booth and start chatting with people in booth chatroom</StyledText>
          </ChatListPlaceholderContainer>
        ) : (
          <>
            <ChatConversationListItem711
              conversation={announcement}
              setChatTarget={setChatTarget}
            />
            <CollapsibleChatGroupView711
              chatList={groupChat}
              type="group"
              setChatTarget={setChatTarget}
              userRole={userRole}
            />
            <CollapsibleChatGroupView711
              chatList={privateChat}
              type="private"
              setChatTarget={setChatTarget}
              userRole={userRole}
            />
          </>
        )
      }
    </>
  );
};

ChatConversationList711.propTypes = {
  setChatTarget: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default ChatConversationList711;
