import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const RootContainer = styled.div<{ left: number; top: number; width: number; height: number }>`
  position: absolute;
  left: ${({ left }) => left}%;
  top: ${({ top }) => top}%;
  width: ${({ width }) => width}%;
  height: ${({ height }) => height}%;
  flex: 1 0 auto;
  transform: translateX(-50%);
  cursor: pointer;
`;

const TitleContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #FFF;
`;

const StyledBackdrop = styled.div<{ bgColor: string }>`
  height: 100%;
  width: 100%;
  background-color: ${({ bgColor }) => bgColor};
  opacity: 0.6;
  position: absolute;
`;

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  letter-spacing: -0.408px;
  display: flex;
  align-items: center;
`;

export interface PageEntryProps {
  children: any;
  left: number;
  top: number;
  width: number;
  height: number;
  bgColor: string;
  link: string;
  isPopupLink?: boolean;
}

const PageEntry: React.FC<PageEntryProps> = ({
  left, top, width, height, bgColor, children, link,
}: PageEntryProps) => (
  <RootContainer left={left} top={top} width={width} height={height}>
    <Link to={link}>
      <StyledBackdrop bgColor={bgColor} />
      <TitleContainer>
        <StyledTitle>
          {children}
        </StyledTitle>
      </TitleContainer>
    </Link>
  </RootContainer>
);

export default PageEntry;
