import { availableLocales } from 'models/localization';

const findAvailableLocale = (obj: Record<string, any>, locale: string) => {
  let val = obj[locale];
  let localeIdx = 0;
  while (!val && localeIdx < availableLocales.length) {
    val = obj[availableLocales[localeIdx]];
    localeIdx += 1;
  }
  return val;
};
export default findAvailableLocale;
