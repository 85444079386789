import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import UserAvatar from 'components/UserAvatar';
import { UserSessionContext } from 'components/UserSession';
import { RONGCLOUD_CHATROOM_PREFIX } from 'appenv';
import GroupChatIcon from 'assets/groupChatIcon.svg';
import AnnounceIcon from 'assets/announceIcon.svg';

const ConversationContainer = styled.div`
  position: relative;
  height: fit-content;
  cursor: pointer;
  display: flex;
  padding: 12px 16px;
  transition: background-color 0.5s ease;
  background-color:
    ${({ isCurrentTarget, isAnnouncement }) => {
    if (isCurrentTarget) {
      return '#DBDEE2';
    }
    if (isAnnouncement) {
      return '#F0F1F2';
    }
    return '#FFF';
  }};

  &:hover {
    background-color: ${({ isCurrentTarget }) => (isCurrentTarget ? '#D8D9DB' : '#F0F1F2')};
  }
`;

const UnreadMessageCount = styled.div`
  position: absolute;
  min-width: 14px;
  width: fit-content;
  height: 14px;
  left: 37px;
  top: 10.5px;
  background: #FF3060;
  color: #FFF;
  border: 0.5px solid #FFF;
  box-sizing: border-box;
  border-radius: 14px;
  font-size: 9px;
  text-align: center;
`;

const ConversationPreview = styled.div`
  margin-left: 12px;
  width: calc(100% - 16px - 32px - 12px);
`;

const MessagePreview = styled.div`
  letter-spacing: 0.3px;
  color: #999;
  font-size: 13px;
  height: 18px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ConversationInfo = styled.div`
  display: flex;
  margin-bottom: 2px;
  justify-content: space-between;
`;

const ConversationName = styled.div`
  width: 120px;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 0;
`;

const ConversationTag = styled.div`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.408px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LatestMessageDate = styled.div`
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.3px;
  color: #999;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledImg = styled.img`
  margin: 7.5px;
`;

const getLatestDate = (message = {}) => {
  const { sentTime } = message;
  if (!sentTime) return '';
  const isToday = moment(sentTime).isSame(moment(), 'day');
  const format = isToday ? 'HH:mm' : 'MMM DD HH:mm';
  return moment(sentTime).format(format);
};

const getUserDescription = (user) => {
  const { description, tag } = user;
  if (description && tag) {
    return `${description}|${tag}`;
  }
  return description || tag;
};

const getLatestMessage = (message) => {
  if (!message) return '';
  let value = '';
  switch (message.messageType) {
    case 'TextMessage':
      value = `${message.content.user.name}: ${message.content.content}`;
      break;
    case 'FileMessage':
      value = `${message.content.user.name}: [FILE]`;
      break;
    default:
      value = '';
  }
  return value;
};

const ChatConversationListItem711 = ({ conversation, setChatTarget, userRole }) => {
  const { targetId } = useSelector((state) => state.chatTarget);
  const { userSession } = useContext(UserSessionContext);

  if (isEmpty(conversation)) {
    return null;
  }

  const iconUrl = (() => {
    switch (conversation.conversationType) {
      case 'group':
        return GroupChatIcon;
      case 'system':
        return AnnounceIcon;
      default:
        return null;
    }
  })();

  const { customAttendeeFields: { vexpo_exhibitor_booth_id: exhibitorBoothID } } = userSession;
  const exhibitorChatroomID = `${RONGCLOUD_CHATROOM_PREFIX}_booth_${exhibitorBoothID}`;

  const conversationClick = () => {
    if (userRole === 'exhibitor' && conversation.id !== exhibitorChatroomID && conversation?.conversationType === 'group') {
      // eslint-disable-next-line no-alert
      alert('You have no permission to access other booths.');
    } else if (conversation.id !== targetId) {
      setChatTarget(conversation.id, conversation.conversationType, conversation.name);
    }
  };

  const userDescription = getUserDescription(conversation);

  const isAttendeeGroupChat = userRole === 'attendee' && conversation.conversationType === 'group';
  const isExhibitorGroupConversation = userRole === 'exhibitor' && conversation.conversationType === 'group';

  const showPreviewCondition = !(isAttendeeGroupChat || (isExhibitorGroupConversation && conversation.id !== exhibitorChatroomID));

  return (
    <ConversationContainer
      isCurrentTarget={targetId === conversation.id}
      isAnnouncement={conversation.id === `${RONGCLOUD_CHATROOM_PREFIX}_announcement`}
      onClick={conversationClick}
      key={conversation.id}
    >
      <UserAvatar name={conversation.name || conversation.id}>
        {
          iconUrl && (
            <StyledImg src={iconUrl} alt="avatar" />
          )
        }
      </UserAvatar>
      {
        showPreviewCondition && conversation.unreadMessageCount > 0 && (
          <UnreadMessageCount>
            {conversation.unreadMessageCount}
          </UnreadMessageCount>
        )
      }
      <ConversationPreview>
        <ConversationInfo>
          <ConversationName>
            {conversation.name || conversation.id}
          </ConversationName>
          {
            showPreviewCondition && (
              <LatestMessageDate>
                {getLatestDate(conversation.latestMessage)}
              </LatestMessageDate>
            )
          }
        </ConversationInfo>
        {
          userDescription && (
            <ConversationTag>
              {userDescription}
            </ConversationTag>
          )
        }
        {
          showPreviewCondition && (
            <MessagePreview>
              {getLatestMessage(conversation.latestMessage)}
            </MessagePreview>
          )
        }
      </ConversationPreview>
    </ConversationContainer>
  );
};

ChatConversationListItem711.propTypes = {
  conversation: PropTypes.shape({
    id: PropTypes.string,
    conversationType: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    tag: PropTypes.string,
    latestMessage: PropTypes.shape({
      messageType: PropTypes.string,
      content: PropTypes.shape({
        content: PropTypes.string,
        user: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    }),
    unreadMessageCount: PropTypes.number,
  }).isRequired,
  setChatTarget: PropTypes.func.isRequired,
  userRole: PropTypes.string,
};

ChatConversationListItem711.defaultProps = {
  userRole: '',
};

export default ChatConversationListItem711;
