import join from 'lodash/join';
import Apps from 'apps';
import FEATURES from './features';

export const APP_ENV = process.env.REACT_APP_ENV;
export const [APP, ENV] = APP_ENV.match(/(.+)_(.+)$/).slice(1);
export const API_URL = process.env.REACT_APP_API_URL;
export const BRAND_COLOR = process.env.REACT_APP_BRAND_COLOR;
export const EVENT_TOKEN = process.env.REACT_APP_EVENT_TOKEN;
export const CMS_API_URL = process.env.REACT_APP_CMS_API_URL;
export const RONGCLOUD_APP_KEY = process.env.REACT_APP_RONGCLOUD_APP_KEY;
export const RONGCLOUD_CHATROOM_PREFIX = process.env.REACT_APP_RONGCLOUD_CHATROOM_PREFIX;
export const MATOMO_SITE_ID = process.env.REACT_APP_MATOMO_SITE_ID;
export const EVENT_START_TIME = process.env.REACT_APP_EVENT_START_TIME;
export const EVENT_END_TIME = process.env.REACT_APP_EVENT_END_TIME;
export const EVENT_NAME = process.env.REACT_APP_EVENT_NAME;
export const EVENT_REGISTER_START_TIME = process.env.REACT_APP_EVENT_REGISTER_START_TIME;
export const EVENT_REGISTER_END_TIME = process.env.REACT_APP_EVENT_REGISTER_END_TIME;
export const EVENT_STAGE_PROGRAMME_REGISTER_END_TIME = process.env.REACT_APP_EVENT_STAGE_PROGRAMME_REGISTER_END_TIME;
export const EVENT_BANNERS = process.env.REACT_APP_EVENT_BANNERS === undefined ? {
  left: [],
  middle: [],
  right: [],
  mobile: [],
} : JSON.parse(process.env.REACT_APP_EVENT_BANNERS);
export const FIRST_VISIBLE_ROUTES = process.env.REACT_APP_FIRST_VISIBLE_ROUTES.split(',');
export const ORGANISER_NAME = process.env.REACT_APP_ORGANISER_NAME;
export const ORGANISER_DOMAIN = process.env.REACT_APP_ORGANISER_DOMAIN;
export const MULTITRACKS_IDS = JSON.parse(process.env.REACT_APP_MULTI_TRACKS_IDS || '[]');
export const PAGE_NAMES = process.env.REACT_APP_PAGE_NAMES
  ? JSON.parse(process.env.REACT_APP_PAGE_NAMES)
  : {
    lobby: 'Lobby',
    hall: 'Hall',
    webinar: 'Webinar',
    exhibitors: 'Exhibitors',
    search: 'Search Results',
    participant: 'Participant',
    contact: 'Contact',
    custom: 'Custom Page',
  };
export const CUSTOM_PAGE_SOURCE = process.env.REACT_APP_CUSTOM_PAGE_SOURCE || null;
export const EVENT_LANDING_REDIRECTION = JSON.parse(process.env.REACT_APP_EVENT_LANDING_REDIRECTION || '{ "eventStartedRedirectTo": "/main-lobby", "eventEndedRedirectToLanding": true }');
export const WEBINAR_START_TIME = process.env.REACT_APP_WEBINAR_START_TIME;
export const ON_DEMAND_WEBINAR_START_TIME = process.env.REACT_APP_ON_DEMAND_WEBINAR_START_TIME;
export const CUSTOM_ORDER = process.env.REACT_APP_CUSTOM_ORDER ? JSON.parse(process.env.REACT_APP_CUSTOM_ORDER) : {};
export const FILTER_LIST = process.env.REACT_APP_FILTER_LIST ? process.env.REACT_APP_FILTER_LIST.split(',').map((filter) => (filter.trim())) : [];
export const FILTER_CUSTOM_LABEL = process.env.REACT_APP_FILTER_CUSTOM_LABEL ? JSON.parse(process.env.REACT_APP_FILTER_CUSTOM_LABEL) : {};
const REACT_APP_ACL = process.env.REACT_APP_ACL || 'function acl() { return true; }';
// eslint-disable-next-line no-eval
export const ACL = eval(`${REACT_APP_ACL}; acl`);

export const ENABLED_FEATURES = FEATURES;
(JSON.parse(process.env.REACT_APP_FEATURES || null) || []).forEach((feature) => {
  ENABLED_FEATURES[feature] = true;
});

export const SUB_HALL = JSON.parse(process.env.REACT_APP_SUB_HALL || '[]');

const boothFields = [
  'id',
  'sort',
  'meetup_url',
  'booth_number',
  'translations.vertical_banner.*',
  'website',
  'booth_color',
  'booth_bg_color',
  'logo.data',
  'translations.language',
  'translations.name',
  'translations.description',
  'translations.extra_fields',
  'tags',
  'country',
  'contacts',
  'files',
  'files.id',
  'files.file',
  'files.file.filename_download',
  'files.file.title',
  'files.file.data',
  'files.file.filesize',
];

switch (APP) {
  case 'polyu618': {
    boothFields.push('booth_design');
    boothFields.push('translations.vertical_banner_file.type');
    boothFields.push('translations.vertical_banner_file.data');
    break;
  }
  case 'paloalto623': {
    boothFields.push('survey');
    boothFields.push('translations.vertical_banner_file.data');
    boothFields.push('translations.vertical_banner_url');
    boothFields.push('translations.link.title');
    boothFields.push('translations.link.url');
    break;
  }
  case 'scmp711': {
    const verticalBannerIndex = boothFields.findIndex((field) => field.startsWith('translations.vertical_banner'));
    if (verticalBannerIndex > -1) {
      boothFields.splice(verticalBannerIndex, 1);
    }
    boothFields.push('type');
    boothFields.push('booth_color_right');
    boothFields.push('translations.*.*');
    ['left', 'center', 'top', 'right', 'bottom'].forEach((position) => {
      boothFields.push(`${position}_banner`);
      boothFields.push(`${position}_banner.file`);
      boothFields.push(`${position}_banner.file.*.*`);
      boothFields.push(`${position}_banner.preview`);
      boothFields.push(`${position}_banner.preview.*.*`);
      boothFields.push(`${position}_banner.description`);
      boothFields.push(`${position}_banner.description.*.*`);
      boothFields.push(`${position}_banner.link`);
      boothFields.push(`${position}_banner.video_thumbnail`);
      boothFields.push(`${position}_banner.video_thumbnail.*.*`);
      boothFields.push(`${position}_banner.cloudflare_stream_id`);
    });
    boothFields.splice(boothFields.findIndex((elem) => elem.startsWith('translations.vertical_banner')), 1);
    let fileKeyIndex = boothFields.findIndex((elem) => elem.startsWith('files'));
    while (fileKeyIndex > -1) {
      boothFields.splice(fileKeyIndex, 1);
      fileKeyIndex = boothFields.findIndex((elem) => elem.startsWith('files'));
    }
    boothFields.push('attachments.id');
    boothFields.push('attachments.title');
    boothFields.push('attachments.description');
    boothFields.push('attachments.thumbnail.*.*');
    boothFields.push('attachments.files.*.*');
    boothFields.push('attachments.webinar.*.*');
    break;
  }
  case 'lighthouse710': {
    boothFields.push('translations.*.*');
    boothFields.push('introduction');
    boothFields.push('livestreams');
    boothFields.push('video_call_url');
    break;
  }
  case 'sph706': {
    boothFields.push('livestreams');
    boothFields.push('video_call_url');
    boothFields.push('translations.*.*');
    break;
  }
  case Apps.Mig0828: {
    boothFields.push('translations.*.*');
    break;
  }
  case Apps.Sph818: {
    boothFields.push('booth_design');
    boothFields.push('video_call_url');
    boothFields.push('translations.*.*');
    break;
  }
  case Apps.pwc918: {
    boothFields.push('video_call_url');
    boothFields.push('translations.*.*');
    break;
  }
  case Apps.VirtualDemo:
  case Apps.PicoX707:
    boothFields.push('booth_color_right');
    boothFields.push('livestreams');
    boothFields.push('video_call_url');
    boothFields.push('booth_design');
    boothFields.push('sales_img.*.*');
    boothFields.push('translations.*.*');
    break;
  case Apps.VirtualPressCon:
    boothFields.push('translations.*.*');
    break;
  case 'cmsconnection':
  case Apps.XtraCMS:
    while (boothFields.length) {
      boothFields.pop();
    }
    break;
  default:
    break;
}

export const FETCH_EXHIBITORS_URL = `${CMS_API_URL}/items/booth?fields=${join(boothFields, ',')}`;

const webinarFields = APP === 'hkstp608'
  ? ['id', 'kind', 'start_time', 'end_time', 'webinar_day.id', 'webinar_day.name', 'webinar_day.date', 'translations.*',
    'speakers.*.*']
  : [
    'id', 'kind', 'start_time', 'end_time', 'webinar_day.id', 'webinar_day.name', 'webinar_day.date', 'translations.*', 'track.*.*',
    'speakers.webinar_speaker.id', 'speakers.webinar_speaker.translations.*', 'speakers.webinar_speaker.profile_picture_url',
    'speakers.webinar_speaker.profile_picture_file.data.full_url',
  ];

switch (APP) {
  case 'scmp711':
    webinarFields.push('speakers.webinar_speaker.contacts');
    break;
  default:
    break;
}

const bannerFields = ['id', 'sort', 'position', 'source_type', 'source_url', 'href', 'thumbnail.*', 'source.*'];

switch (APP) {
  case 'scmp711':
    bannerFields.push('banner_fit');
    break;
  default:
    break;
}

export const FETCH_WEBINAR_URL = `${CMS_API_URL}/items/webinar_session?fields=${join(webinarFields, ',')}`;
export const FETCH_EVENT_URL = `${CMS_API_URL}/items/event`;
export const FETCH_DEFAULT_BOOKMARK_URL = `${CMS_API_URL}/items/default_bookmark?fields=id,sort,removable,attachment.*`;
export const FETCH_BANNER_URL = `${CMS_API_URL}/items/banner?fields=${join(bannerFields)}`;
export const FETCH_LANDING_AGENDA_URL = `${CMS_API_URL}/items/landing_agenda?fields=id,hide_content,hide_speaker,webinar,translations.*`;
export const FETCH_PRODUCT_URL = 'https://vexpo-product-api.eventxtra.com';

export const VIDEO_CALL_URL = process.env.REACT_APP_VIDEO_CALL_URL;

export default {
  APP_ENV,
  API_URL,
  EVENT_TOKEN,
  CMS_API_URL,
  FETCH_EXHIBITORS_URL,
  RONGCLOUD_APP_KEY,
  RONGCLOUD_CHATROOM_PREFIX,
  MATOMO_SITE_ID,
  ENABLED_FEATURES,
  ACL,
  EVENT_NAME,
  CUSTOM_PAGE_SOURCE,
  PAGE_NAMES,
  WEBINAR_START_TIME,
  ON_DEMAND_WEBINAR_START_TIME,
  CUSTOM_ORDER,
  FILTER_LIST,
  FILTER_CUSTOM_LABEL,
};
