/* eslint-disable camelcase */
import React, { useState, useContext, useMemo } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import {
  APP, ENV,
  RONGCLOUD_CHATROOM_PREFIX,
  BRAND_COLOR,
  PAGE_NAMES,
} from 'appenv';
import Apps from 'apps';
import Routes from 'Routes';
import ChatContext from 'components/chat/ChatContext';
import isChatroomDisabled from 'components/chat/isChatroomDisabled';
import { useTranslation } from 'react-i18next';

import InsertInvitationIcon from 'assets/calendarWhite.svg';
import EmailIcon from 'assets/emailWhite.svg';
import chatroomIcon from 'assets/chatroomWhite.svg';
import CloseBtn from 'assets/closeBtn.svg';
import LogoPlaceholder from 'assets/logoPlaceholder.svg';
import XtraBreadcrumbs from 'components/XtraBreadcrumbs';
import playButton from 'assets/play-button.png';
import MobileBoothBannerSlider from './components/mobileBanner/MobileBoothBannerSlider';
import { VideoSources } from './templates/Booth624Template';

const FlexContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 20px;
  position: relative;
`;

const LogoContainer = styled.div`
  width: 100%;
  margin: 16px auto;
  text-align: center;
`;

const LogoContainer624 = styled.div`
  width: 200px;
  margin: 16px auto;
  text-align: center;
  cursor: pointer;
  user-select: none;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

const StyledImg = styled.img`
  width: ${({ hasLogo }) => (hasLogo ? '100%' : '32px')};
  height: ${({ hasLogo }) => (hasLogo ? '100%' : '32px')};
`;

const StyledIcon = styled.img`
  filter: grayscale(100%) brightness(900%);
`;

const NameText = styled.div`
  font-size: 18px;
  line-height: 18px;
  margin: 0 16px 8px;
  text-align: center;
`;

const PopupBtn = styled.div`
  position: fixed;
  bottom: 50px;
  right: 20px;
  width: 60px;
  height: 60px;
  color: #FFF;
  text-align: center;
  line-height: 76px;
  background: ${BRAND_COLOR || '#0CA58B'};
  border-radius: 60px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.24);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BubbleBtnGroup = styled.div`
  position: absolute;
  bottom: 115px;
  right: 26px;
  background-color: rgba(0, 0, 0, 0);
`;

const StyledHyperLink = styled.a`
  text-decoration: none;
  color: #282B40;

  &:visited {
    color: #282B40;
  }
`;

const BunnbleBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const BubbleBtnText = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: #282B40;
  margin-right: 16px;
`;

const BubbleBtn = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 48px;
  line-height: 65px;
  color: #FFF;
  background: ${BRAND_COLOR || '#0CA58B'};
  text-align: center;
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledModal = styled(Modal)`
  background-color: rgba(255, 255, 255, 0.89);

  & > div {
    background-color: rgba(0, 0, 0, 0) !important;

    &:focus {
      outline: none;
    }
  }
`;

const StyledPlayButton = styled.img`
  width: 30px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const OverflowBlocker = styled.div`
  width: 17px;
  background-color: #FEFEFE;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
`;

const OverflowBlockerRight = styled(OverflowBlocker)`
  right: 0;
  left: auto;
`;

const possibleLogoBannerKeys = ['logoBanner', 'top'];

const MobileProfile = ({ booth }) => {
  const { t } = useTranslation();

  const boothBanners = booth?.banners ?? booth?.banner ?? {};
  const bannerFiles = useMemo(() => {
    const bannerDataList = Object.entries(boothBanners)
      .filter((data) => data)
      .map(([position, data]) => ({
        ...(APP === 'scmp711' ? data[0] : data),
        position,
      }))
      .sort((a, b) => {
        if (possibleLogoBannerKeys.includes(a.position)) return -1;
        if (possibleLogoBannerKeys.includes(b.position)) return 1;
        return 0;
      });
    return bannerDataList.filter((banner) => banner.preview ?? banner.file);
  }, [boothBanners]);

  const remappedBannerFiles = useMemo(() => bannerFiles.map((banner) => {
    const bannerFile = banner.file || banner.preview;
    const bannerPreview = banner.preview ?? banner.file;
    // eslint-disable-next-line camelcase
    let src = bannerPreview?.data?.thumbnails?.find((thumb) => thumb.url.endsWith('directus-large-contain')).url;

    const { type: rawType } = bannerFile;
    let type = rawType?.split('/')[0];
    if (banner.cloudflare_stream_id) {
      type = 'cfStream';
    }
    let href = banner?.link;
    // eslint-disable-next-line camelcase
    const thumbnail = banner.video_thumbnail?.data?.full_url ?? banner.video_preview?.data?.full_url ?? banner.preview?.data?.full_url;
    const bannerFit = 'contain';

    switch (type) {
      case 'cfStream':
        // eslint-disable-next-line camelcase
        src = banner?.cloudflare_stream_id;
        break;
      case 'video': {
        // eslint-disable-next-line camelcase
        src = (banner.file ?? banner.preview)?.data?.full_url;
        break;
      }
      case 'image':
      default: {
        // eslint-disable-next-line camelcase
        href = href ? `http://${href.replace(/\b(https:\/\/|http:\/\/)/, '')}` : bannerFile?.data?.full_url;
        src = (banner.file ?? banner.preview)?.data?.full_url;
      }
    }

    return {
      src,
      type,
      href,
      thumbnail,
      banner_fit: bannerFit,
    };
  }), [bannerFiles]);

  const [modalOpen, setModalOpen] = useState(false);

  const { showChatWindow } = useContext(ChatContext);

  const mailToLink = useMemo(() => {
    const { emailContacts } = booth;
    if (emailContacts && emailContacts.length > 0) {
      const email = emailContacts.find((eachemailContact) => (eachemailContact.type === 'email'));
      if (email?.contact) {
        switch (APP) {
          case 'scmp711':
            return `mailto:${email.contact}?subject=Enquiry from Singapore Property Festival 2020`;
          case Apps.Mig0828:
            return `mailto:${email.contact}?subject=Enquiry from The 12th InfoSecurity Summit 2020&body=Your registered Email:%0D%0AYour Message:`;
          default:
            return `mailto:${email.contact}`;
        }
      }
    }
    return '';
  }, [booth]);

  const openChatWindow = () => {
    window.trackingEvent('Booth', 'Group_Chat_Click', `${booth.id} - ${booth.name}`);
    window.trackingEvent('PublicChatroom_Enter', 'Booth_Networking_Widget_Click', `${booth.id} - ${booth.name}`);
    setModalOpen(false);
    const chatroomID = `${RONGCLOUD_CHATROOM_PREFIX}_booth_${booth.id}`;
    showChatWindow(chatroomID, 'group', booth.name);
  };

  const clickMeetup = () => {
    window.trackingEvent('Booth', 'Meetup_Click', `${booth.id} - ${booth.name}`);
  };

  const clickLeaveMessage = () => {
    window.trackingEvent('Booth', 'Leave a Message_Click', `${booth.id} - ${booth.name}`);
  };

  const onPlayButtonClick = () => window.open(VideoSources[parseInt(booth.boothNumber, 10) - 1], '_blank');

  const SelectedLogoContainer = (() => {
    switch (APP) {
      case 'ms624':
        return (
          <LogoContainer624>
            <StyledPlayButton onClick={onPlayButtonClick} src={playButton} alt="Play Button" />
            <StyledImg hasLogo={booth.logo} src={booth.logo || LogoPlaceholder} />
          </LogoContainer624>
        );
      default: return (
        <>
          <LogoContainer>
            <MobileBoothBannerSlider assets={remappedBannerFiles} disableAutoplay />
          </LogoContainer>
          <OverflowBlocker />
          <OverflowBlockerRight />
        </>
      );
    }
  })();

  const shouldShowFab = (
    APP !== 'ms624'
    && (
      booth.meetupUrl
      || mailToLink
      || APP !== 'paloalto623'
    )
  );

  return (
    <>
      <XtraBreadcrumbs
        paths={[
          { name: PAGE_NAMES.lobby || t('page.lobby', 'Lobby'), to: Routes.lobby },
          { name: PAGE_NAMES.hall || t('page.hall', 'Exhibition Hall'), to: Routes.hall },
          { name: booth.name },
        ]}
      />
      <FlexContainer>
        {SelectedLogoContainer}
        <NameText>{booth.name}</NameText>
      </FlexContainer>
      {shouldShowFab && (
        <PopupBtn onClick={() => setModalOpen(true)}>
          <StyledIcon src={chatroomIcon} alt="chatroom" />
        </PopupBtn>
      )}
      <StyledModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <BubbleBtnGroup>
          {
            booth.meetupUrl && (
              <StyledHyperLink href={booth.meetupUrl} target="_blank" rel="noopener noreferrer">
                <BunnbleBtnContainer onClick={clickMeetup}>
                  <BubbleBtnText>
                    Meetup
                  </BubbleBtnText>
                  <BubbleBtn>
                    <StyledIcon src={InsertInvitationIcon} alt="Insert invitation" />
                  </BubbleBtn>
                </BunnbleBtnContainer>
              </StyledHyperLink>
            )
          }
          {
            mailToLink && (
              <StyledHyperLink href={mailToLink} target="_blank" rel="noopener noreferrer">
                <BunnbleBtnContainer onClick={clickLeaveMessage}>
                  <BubbleBtnText>
                    Leave a Message
                  </BubbleBtnText>
                  <BubbleBtn>
                    <StyledIcon src={EmailIcon} alt="Leave a message" />
                  </BubbleBtn>
                </BunnbleBtnContainer>
              </StyledHyperLink>
            )
          }
          {
            // 801 chat room hide icon after live day
            !['paloalto623', Apps.Jetro817].includes(APP) && !isChatroomDisabled() && (
              <BunnbleBtnContainer onClick={openChatWindow}>
                <BubbleBtnText>
                  {
                    APP === 'hkstp608' ? 'Public Chat' : 'Chat'
                  }
                </BubbleBtnText>
                <BubbleBtn>
                  <StyledIcon src={chatroomIcon} alt="Chatroom" />
                </BubbleBtn>
              </BunnbleBtnContainer>
            )
          }
          <PopupBtn onClick={() => setModalOpen(false)}>
            <StyledIcon src={CloseBtn} alt="Close" />
          </PopupBtn>
        </BubbleBtnGroup>
      </StyledModal>
    </>
  );
};

MobileProfile.propTypes = {
  booth: propTypes.shape().isRequired,
};

export default MobileProfile;
