import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BRAND_COLOR, ENABLED_FEATURES } from 'appenv';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import { Booth } from 'models/cms/booths';
import { useSelector } from 'react-redux';
import { selectBoothProductsByLocale } from 'models/boothProducts';
import ProductTab from 'components/product/ProductTab';
import { selectLocale } from 'models/localization';
import { BoothProfileTabsContext } from '../../components/BoothProfileTabsContext';
import TabContentSwitcher from './TabContentSwitcher';

const Container = styled.div<{ isDesktop: boolean }>`
  width: ${({ isDesktop }) => (isDesktop ? '70%' : '100%')};
  height: auto;
  margin: 0 auto;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background-color: #FFF;
  letter-spacing: 0.3px;

  .MuiTab-wrapper {
    font-size: ${({ isDesktop }) => (isDesktop ? '24px' : '18px')};
  }

  button.MuiButtonBase-root.MuiTab-root {
    margin-right: ${({ isDesktop }) => (isDesktop ? '40px' : '20px')};
  }
`;

const StyledTabs = withStyles({
  root: {
    padding: 24,
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '4px',
    '& > div': {
      maxWidth: 139,
      width: '100%',
      backgroundColor: BRAND_COLOR || '#0CA58B',
    },
  },
  scrollable: {
    overflowX: 'auto',
  },
  // eslint-disable-next-line react/jsx-props-no-spreading
})((props: any) => <Tabs {...props as any} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#A2AAB3',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(24),
    fontFamily: 'Lato',
    padding: '0',
    minWidth: 'unset',
    '&:focus': {
      opacity: 1,
    },
  },
  selected: {
    color: BRAND_COLOR || '#0CA58B',
  },
  // eslint-disable-next-line react/jsx-props-no-spreading
}))((props: any) => <Tab disableRipple {...props} />);

const TabPanelContainer = styled.div`
  padding-bottom: 20px;
`;

const TabPanel = ({ children, value, index }) => (
  <TabPanelContainer
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && (
      children
    )}
  </TabPanelContainer>
);

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const BoothProfileTabs = ({ booth, isDesktop }: {
  booth: Booth;
  isDesktop: boolean;
}) => {
  const { tabs } = booth.rootContentElement || { tabs: [] };
  const boothProducts = useSelector(selectBoothProductsByLocale);
  const locale = useSelector(selectLocale);
  const { activeTabKey, setActiveTabKey } = useContext(BoothProfileTabsContext);
  const onActiveTabKeyChange = (event, tabKey) => {
    // window.trackingEvent('Booth', `Booth_Profile_Tab_${tabKey + 1}_Click`, `${id} - ${name}`);
    setActiveTabKey(tabKey);
  };

  const handleLinkClink = (event) => {
    if (event?.target?.tagName === 'A') {
      event.target.target = '_blank';
      event.target.rel = 'noopener noreferrer';
    }
  };

  if (tabs.length === 0) return null;

  return (
    <Container isDesktop={isDesktop}>
      <StyledTabs scrollButtons="off" variant="scrollable" value={activeTabKey} onChange={onActiveTabKeyChange} aria-label="Booth Details">
        {tabs.map((tab) => (
          <StyledTab key={tab.title[locale]} label={tab.title[locale]} aria-label={tab.title[locale]} />
        ))}
      </StyledTabs>
      <SwipeableViews
        axis="x"
        index={activeTabKey}
        disabled
        ignoreNativeScroll
        resistance
        onClick={handleLinkClink}
      >
        {tabs.map((tab, index) => (
          <TabPanel key={index} value={activeTabKey} index={index}>
            {tab.title.en === 'Products Info' && ENABLED_FEATURES.productPage ? (
              <ProductTab boothProducts={boothProducts} />
            ) : (
              <TabContentSwitcher key={tab.title[locale]} contents={tab.body} />
            )}
          </TabPanel>
        ))}
      </SwipeableViews>
    </Container>
  );
};

export default BoothProfileTabs;
