import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { LocalizedProduct } from 'models/types/products';
import ProductItem from 'components/product/ProductItem';
import isChatroomDisabledByReed from 'components/chat/utils/isChatroomDisabledByReed';

const RootContainer = styled.div`
  padding: 8px 24px 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const StyledPaper = styled(Paper)`
  margin: 8px 16px;
  align-items: stretch;
`;

const ProductTab = ({ boothProducts }: {
  boothProducts: LocalizedProduct[];
}) => {
  const hideChatLink = useMemo(() => isChatroomDisabledByReed(), []);

  return (
    <RootContainer>
      {Object.values(boothProducts).map((product) => (
        <StyledPaper elevation={4} key={product.id}>
          <ProductItem product={product} hideChatLink={hideChatLink} />
        </StyledPaper>
      ))}
    </RootContainer>
  );
};

export default ProductTab;
