import { en as enTranslation, ja as jaTranslation } from './translation';
import { en as enLanding, ja as jaLanding } from './landing';
import { en as enCommon, ja as jaCommon } from './common';

export default {
  en: {
    translation: enTranslation,
    landing: enLanding,
    common: enCommon,
  },
  ja: {
    translation: jaTranslation,
    landing: jaLanding,
    common: jaCommon,
  },
};
