import React, {
  useEffect,
} from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectLocale } from 'models/localization';
import 'objectFitPolyfill';
import { DesignElementKind, DesignElement } from 'models/cms/booths';

import BannerPDF from './BannerPDF';
import BannerRichText from './BannerRichText';
import BannerVideo from './BannerVideo';
import BannerImage from './BannerImage';
import DescriptionBanner from './DescriptionBanner';

const GenericCMSBoothBannerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export interface GenericCMSBoothBannerContentProps {
  bannerData: DesignElement;
  autoPlayVideo?: boolean;
  isDescriptionBanner?: boolean;
  onBannerClick?: () => any;
}

const GenericCMSBoothBannerContent: React.FC<GenericCMSBoothBannerContentProps> = ({
  bannerData: {
    kind,
    name = '',
    image = {},
    video = {},
    contentRichtextHtml = {},
    thumbnailImage = {},
    pdf = {},
  },
  autoPlayVideo = false,
  isDescriptionBanner = false,
  onBannerClick = () => null,
}: GenericCMSBoothBannerContentProps) => {
  const locale = useSelector(selectLocale);

  useEffect(() => {
    (window as any).objectFitPolyfill();
  }, [image]);

  switch (kind) {
    case DesignElementKind.Image: {
      const imageUrl: string = image[locale]?.originalImageUrl;
      if (!imageUrl) return null;
      return (
        <BannerImage
          src={imageUrl}
          alt={`${name} Banner`}
        />
      );
    }
    case DesignElementKind.Video: {
      const videoUrl: string = video[locale]?.playbackUrl;
      const thumbnailUrl = thumbnailImage[locale]?.originalImageUrl;
      if (!videoUrl) return null;
      return <BannerVideo autoPlay={autoPlayVideo} videoUrl={videoUrl} thumbnailUrl={thumbnailUrl} />;
    }
    case DesignElementKind.Richtext: {
      const htmlContent: string = contentRichtextHtml[locale] || '';
      if (isDescriptionBanner) return <DescriptionBanner htmlContent={htmlContent} onViewMoreButtonClick={onBannerClick} />;
      return <BannerRichText htmlContent={htmlContent} />;
    }
    case DesignElementKind.PDF: {
      const pdfUrl = pdf[locale]?.fileUrl;
      const thumbnailUrl = thumbnailImage[locale]?.originalImageUrl;
      if (!(pdfUrl || thumbnailUrl)) return null;
      return <BannerPDF pdfUrl={pdfUrl} thumbnailUrl={thumbnailUrl} />;
    }
    default:
      return null;
  }
};

export interface GenericCMSBoothBannerProps {
  bannerData: DesignElement;
  onBannerClick?: () => any;
  autoPlayVideo?: boolean;
  isDescriptionBanner?: boolean;
}

const GenericCMSBoothBanner: React.FC<GenericCMSBoothBannerProps> = ({
  bannerData,
  onBannerClick = () => null,
  autoPlayVideo = false,
  isDescriptionBanner = false,
}: GenericCMSBoothBannerProps) => (
  <GenericCMSBoothBannerContainer>
    {bannerData && (
      <GenericCMSBoothBannerContent
        bannerData={bannerData}
        autoPlayVideo={autoPlayVideo}
        onBannerClick={onBannerClick}
        isDescriptionBanner={isDescriptionBanner}
      />
    )}
  </GenericCMSBoothBannerContainer>
);

export default GenericCMSBoothBanner;
