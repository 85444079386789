import { EVENT_TOKEN, API_URL } from 'appenv';
import Apps, { reedApps } from 'apps';
import { envSwitch } from 'utils/envUtils';

export const apiUrl = envSwitch([
  [[Apps.Jetro817, Apps.Mig0828, Apps.Uzabase826, Apps.Eventage824, ...reedApps], 'https://api-virtual.eventxtra.com/graphql'],
], API_URL);

export const eventToken = envSwitch([
  [Apps.Jetro817, 'gbtyes6dGZHmhdKTiu4RYxyw'],
  [Apps.Mig0828, 'KKMnYc8zU2UTkxrBGptpCWyk'],
  [Apps.Reed902, 'P5fqMS24CCsFk8sZ4HhdJQCV'],
  [Apps.ReedMonoN909, 'mCMz7BsfwgRiB8b88usCGQFZ'],
  [Apps.ReedCosme909, 'sjeTxYwcdp3xv6tDPhdkitT9'],
  [Apps.ReedUi909, 'UuLkUt9zW4TFagEQXLThA6oz'],
  [Apps.ReedSoumn916, 'MAdSPZaKXxrP9h86ukDxfWmd'],
  [Apps.ReedEdix916, '6Rx958Uc8DNH5vtqJYrZsW4H'],
  [Apps.Uzabase826, '4vBLu6V8A6hg2dacHXpsh225'],
  [Apps.Emsd831, '8xa5oDrcinQeyTBzXXJNfjmp'],
  [Apps.Eventage824, 'uHSX3LEfwBWj3GCg2fqcP1dr'],
], EVENT_TOKEN);
