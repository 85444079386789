enum Apps {
  HKSTP608 = 'hkstp608',
  Jetro817 = 'jetro817',
  Lighthouse710 = 'lighthouse710',
  Ms624 = 'ms624',
  Neoventure1012 = 'neoventure1012',
  Paloalto623 = 'paloalto623',
  PicoX707 = 'picox707',
  PolyU618 = 'polyu618',
  SCMP711 = 'scmp711',
  SPH706 = 'sph706',
  Uzabase826 = 'uzabase826',
  VirtualDemo = 'xtra',
  Sph818 = 'sph818',
  Mig0828 = 'mig0828',
  Emsd831 = 'emsd831',
  VirtualPressCon = 'xtrapresscon',
  XtraCMS = 'xtracms',
  Reed902 = 'reed902',
  ReedMonoN909 = 'reedMonoN909',
  ReedCosme909 = 'reedCosme909',
  ReedUi909 = 'reedUi909',
  ReedSoumn916 = 'reedSoumn916',
  ReedEdix916 = 'reedEdix916',
  Cuhk925 = 'cuhk925',
  ReedSoumn918 = 'reedSoumn918',
  ReedEdix918 = 'reedEdix918',
  Eventage824 = 'eventage824',
  pwc918 = 'pwc918',
  Vfc925 = 'Vfc925'
}

export const reedApps = [
  Apps.Reed902,
  Apps.ReedMonoN909,
  Apps.ReedCosme909,
  Apps.ReedUi909,
  Apps.ReedSoumn916,
  Apps.ReedEdix916,
];

export default Apps;
