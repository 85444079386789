import React, { useCallback, MouseEventHandler } from 'react';
import PropTypes from 'prop-types';
import { ENABLED_FEATURES, BRAND_COLOR } from 'appenv';
import styled from 'styled-components';
import {
  ButtonLabel, ButtonIcon, RoundedButtonLink, RoundedButton,
} from 'components/input/RoundedButton';
import BookmarkedIcon from 'components/icons/Bookmarked';
import BookmarkIcon from 'components/icons/Bookmark';
import { Bookmark } from 'models/types/bookmarks';
import { useSelector, useDispatch } from 'react-redux';
import { selectBookmarkById, addBookmark, removeBookmark } from 'models/bookmarks';

const ItemContainer = styled.div`
  margin: 12px 24px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;

  &:first-of-type {
    margin-top: 0;
  }
`;

const LabelContainer = styled.a`
  font-size: 16px;
  line-height: 16px;
  display: block;
  word-break: break-word;
  color: #5C8DF3;

  @media (min-width: 960px) {
    color: #000;
    text-transform: none;
    text-decoration: none;
  }
`;

const StyledButtonLabel = styled(ButtonLabel)`
  display: inline-block;
`;

const AttachmentViewButton = styled(RoundedButtonLink)`
  background: ${BRAND_COLOR || '#8B383A'};
  width: 96px;
  height: 34px;

  @media (max-width: 959px) {
    display: none;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;

  & > * {
    margin-left: 8px;
  }
`;

const BookmarkLabel = styled(StyledButtonLabel)`
  @media (max-width: 960px) {
    display: none;
  }
`;

const BoothPitchAttachmentBookmarkButton = styled(RoundedButton)`
  width: 42px;
  background: #FFF;
  color: #484848;
  border: none;

  @media (min-width: 960px) {
    width: 136px;
    border: 2px solid #DBDEE2;
  }
`;

const BookmarkButton = styled(ButtonIcon)`
  @media (max-width: 960px) {
    margin-right: 0;
  }
`;

const AttachmentBookmarkIcon = ({ isBookmarked }) => (
  <BookmarkButton>
    {isBookmarked ? <BookmarkedIcon /> : <BookmarkIcon />}
  </BookmarkButton>
);
AttachmentBookmarkIcon.propTypes = {
  isBookmarked: PropTypes.bool.isRequired,
};

const BoothFileBookmark = ({
  bookmark,
  isBookmarked,
  onRemoveBookmark,
  onAddBookmark,
}: {
  bookmark: Bookmark;
  isBookmarked: boolean;
  onAddBookmark: (bookmark: Bookmark) => void;
  onRemoveBookmark: (bookmarkId: string) => void;
}) => {
  const onBookmarkClick: React.MouseEventHandler = useCallback((event: React.MouseEvent) => {
    const { id } = bookmark;
    if (isBookmarked) {
      onRemoveBookmark(id);
    } else {
      onAddBookmark(bookmark);
    }
  }, [isBookmarked, bookmark, onAddBookmark, onRemoveBookmark]);
  return (
    <BoothPitchAttachmentBookmarkButton onClick={onBookmarkClick}>
      <AttachmentBookmarkIcon isBookmarked={isBookmarked} />
      <BookmarkLabel>
        {isBookmarked ? 'Bookmarked' : 'Bookmark'}
      </BookmarkLabel>
    </BoothPitchAttachmentBookmarkButton>
  );
};

const BoothFileRow = ({
  bookmark,
  removable = true,
}: {
  bookmark: Bookmark;
  removable?: boolean;
}) => {
  const {
    id,
    fileUrl,
    filename,
  } = bookmark;

  const dispatch = useDispatch();
  const isBookmarked = !!useSelector((state) => selectBookmarkById(state as any, id));

  const handleAddBookmark = useCallback(() => {
    dispatch(addBookmark(bookmark));
  }, [dispatch, bookmark]);
  const handleRemoveBookmark = useCallback(() => {
    dispatch(removeBookmark(id));
  }, [dispatch, id]);

  return (
    <ItemContainer>
      <LabelContainer target="_blank" rel="noopener noreferrer" href={fileUrl}>
        <StyledButtonLabel>
          {filename}
        </StyledButtonLabel>
      </LabelContainer>
      <ActionsContainer>
        {
          ENABLED_FEATURES.bookmarks && removable && (
            <BoothFileBookmark
              bookmark={bookmark}
              isBookmarked={isBookmarked}
              onAddBookmark={handleAddBookmark}
              onRemoveBookmark={handleRemoveBookmark}
            />
          )
        }
        <AttachmentViewButton target="_blank" rel="noopener noreferrer" href={fileUrl}>
          View
        </AttachmentViewButton>
      </ActionsContainer>
    </ItemContainer>
  );
};

export default BoothFileRow;
