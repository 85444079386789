import React from 'react';
import styled from 'styled-components';
import { envSwitch } from 'utils/envUtils';
import Apps from 'apps';

const objectFit = envSwitch([
  [Apps.Uzabase826, 'cover'],
], 'contain');

const BannerImageContainer = styled.img`
  width: 100%;
  height: 100%;
  object-fit: ${objectFit};
  object-position: center;
`;

const BannerImage = (props): React.ReactElement => (
  <BannerImageContainer
    {...props}
    data-object-fit={objectFit}
    data-object-position="center"
  />
);

export default BannerImage;
