const bannerUtils = {
  toImage: (src: string, href = null) => ({
    src,
    href,
    type: 'image',
  }),
};

export const en = {
  entry: {
    to_exhibition_hall: 'Enter Exhibition Hall',
    how_to_use_oms: '<b>How to utilize</b><b>virtual expo?</b>',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/a9b32a85-3507-4cea-bcd5-1742147c2f15.png',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/56ff43a9-c805-4243-8562-a2cfb45eeeaf.png',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/75660aa3-73e2-45f5-b8f1-973e71365a7e.png',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/c6430145-6030-40c6-8890-236c9d0107e2.png',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/ebc34fa1-6afc-4cfd-ab01-ae92908c0a48.jpg',
        'https://www.edix-expo.jp/en-gb.html',
      ),
    ],
    mobile: [],
  },
};

en.banners.mobile = [
  ...en.banners.middle,
  ...en.banners.left,
  ...en.banners.right,
];

export const ja = {
  entry: {
    to_exhibition_hall: '展示会場はこちら',
    how_to_use_oms: '<b>はじめに</b><i>オンライン来場・商談サービスの使い方</i>',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/a9b32a85-3507-4cea-bcd5-1742147c2f15.png',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/56ff43a9-c805-4243-8562-a2cfb45eeeaf.png',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/75660aa3-73e2-45f5-b8f1-973e71365a7e.png',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/c6430145-6030-40c6-8890-236c9d0107e2.png',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/76251b38-ec60-4936-92d2-f76fea1282b5.jpg',
        'https://www.edix-expo.jp/ja-jp.html',
      ),
    ],
    mobile: [],
  },
};

ja.banners.mobile = [
  ...ja.banners.middle,
  ...ja.banners.left,
  ...ja.banners.right,
];
