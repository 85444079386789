import { Product, LocalizedProduct } from 'models/types/products';
import { availableLocales } from 'models/localization';
import findAvailableLocale from './findAvailableLocale';

const localizableFields = {
  boothId: false,
  booth: false,
  details: true,
  eventToken: false,
  id: false,
  images: true,
  name: true,
  providerId: false,
  shortDescription: true,
  tags: true,
};

interface CMSImage {
  originalImageUrl: string;
  previewImageUrl: string;
}

const findLocalizedImage = (images: {
  [locale: string]: CMSImage[];
}, locale: string) => {
  let val = images[locale];
  let localeIdx = 0;
  while (!(val.length) && (localeIdx < availableLocales.length)) {
    val = images[availableLocales[localeIdx]] || [];
    localeIdx += 1;
  }
  return val;
};

const getLocalizedProduct = (product: Product, locale: string): LocalizedProduct => {
  const localizedProduct = {} as any;
  const localizeHelper = (obj: any, key: string) => {
    if (key in localizableFields && localizableFields[key]) {
      if (key === 'details') {
        return Object.keys(obj).reduce((acc, curr) => {
          acc[curr] = findAvailableLocale(obj[curr], locale);
          return acc;
        }, {});
      }
      if (key === 'images') {
        const localizedImages = findLocalizedImage(obj, locale);
        [localizedProduct.image] = localizedImages;
        return localizedImages;
      }
      return findAvailableLocale(obj, locale);
    }
    return obj;
  };
  Object.keys(product || {}).forEach((key) => {
    localizedProduct[key] = localizeHelper(product[key], key);
  });
  return localizedProduct;
};

export default getLocalizedProduct;
