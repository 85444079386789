import React, {
  useContext,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  EVENT_NAME,
  PAGE_NAMES,
  BRAND_COLOR,
  APP,
  ENABLED_FEATURES,
} from 'appenv';
import {
  getFilterOptionByExhibitor,
  filterExhibitorsByKeywords,
  selectExhibitorIds,
} from 'models/exhibitors';
import {
  selectBoothIds,
  getFilterOptions,
  filterBoothsByFilterOptions,
} from 'models/cms/booths';
import isChatroomDisabled from 'components/chat/isChatroomDisabled';
import { useTranslation } from 'react-i18next';

import BackgroundImgDefault from 'assets/hall/background.jpg';
import Background711 from 'assets/background-711.jpg';
import Background817 from 'assets/hall/background-hall-817.png';
import ChatroomIcon from 'assets/chatroomWhite.svg';

import isChatroomDisabledByReed from 'components/chat/utils/isChatroomDisabledByReed';
import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import ChatContext from 'components/chat/ChatContext';
import Apps, { reedApps } from 'apps';
import { envSwitch } from 'utils/envUtils';
import useFilter from './filterTemplates/useFilter';
import HallListComponent from './HallListComponent';
import BoothFilterSwitch from './BoothFilterSwitch';
import Background624Container from '../main_lobby/templates/Background624Conatiner';
import 'objectFitPolyfill';

const noBackgroundList = ['ms624', 'scmp711'];

const RootContainerUI = styled.div`
  min-width: 100%;
  height: 100%;
  overflow: ${({ hasFilter }) => (hasFilter ? 'auto' : 'hidden')};

  @media (min-width: 960px) {
    background: url(${({ backgroundImg }) => backgroundImg});
    background-color: ${({ backgroundImg }) => (!backgroundImg && !noBackgroundList.includes(APP) && '#F0F1F2')};
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1440px auto;
  }

  @media (max-width: 960px) {
    background-color: #F0F1F2;
  }

  @media (min-width: 1440px), (min-height: 960px) {
    background-size: cover;
  }
`;

function RootContainer({ backgroundImg, children, hasFilter }) {
  return (
    <RootContainerUI backgroundImg={backgroundImg} hasFilter={hasFilter}>{children}</RootContainerUI>
  );
}

RootContainer.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundImg: PropTypes.string.isRequired,
  hasFilter: PropTypes.bool,
};

RootContainer.defaultProps = {
  hasFilter: false,
};

const BoothFilterListContainer = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;

  @media (max-width: 959px) {
    display: block;
  }
`;

const BoothListContainer = styled.div`
  width: ${({ hasFilter }) => (hasFilter ? 'calc(100% - 300px)' : '100%')};
  height: 100%;
  overflow: ${APP === 'scmp711' ? 'auto' : 'hidden'};
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  margin: auto;

  @media only screen and (max-width: 959px) {
    width: 100%;
  }
`;

const PopupBtn = styled.div`
  position: fixed;
  bottom: 50px;
  right: 20px;
  width: 60px;
  height: 60px;
  color: #FFF;
  text-align: center;
  line-height: 76px;
  background: ${BRAND_COLOR || '#0CA58B'};
  border-radius: 60px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.24);
  z-index: 1100;
  cursor: pointer;
`;

const BackgroundVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;

  @media only screen and (max-width: 959px) {
    display: none;
  }
`;

const BoothListWrapper = styled.div`
  height: 100%;
  flex-basis: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  border-radius: 0 !important;
  padding: 0;
  width: inherit;
  flex-direction: column;
  justify-content: flex-start;
`;

const selectors = ENABLED_FEATURES.xtraCMS
  ? {
    fetching: (state) => state.cms.booths.fetching,
    boothIds: selectBoothIds,
    getFilterOptions,
    filterByKeywords: filterBoothsByFilterOptions,
  }
  : {
    fetching: (state) => state.exhibitors.fetching,
    boothIds: selectExhibitorIds,
    getFilterOptions: getFilterOptionByExhibitor,
    filterByKeywords: filterExhibitorsByKeywords,
  };

const HallPage = () => {
  const { t } = useTranslation();

  const backgroundImgSrc = envSwitch([
    [Apps.Paloalto623, ''],
    [Apps.SCMP711, Background711],
    [Apps.PicoX707, 'https://contents-virtual.eventxtra.com/uploads/vxevents_staging/originals/c84fe0a6-a25d-4afb-adc3-f4abe5b0a3bb.jpeg'],
    [Apps.VirtualDemo, 'https://contents-virtual.eventxtra.com/uploads/virtualdemo_staging/originals/90ceb449-28e8-458a-a3af-3f82a8545afe.png'],
    [Apps.Sph818, 'https://contents-virtual.eventxtra.com/uploads/virtualdemo_staging/originals/54c42c0b-6663-436e-9b85-c2454af04308.png'],
    [Apps.Mig0828, 'https://contents-virtual.eventxtra.com/uploads/mig828_staging/originals/dee3a214-994a-4356-818c-23e474927a4d.jpg'],
    [Apps.Emsd831, 'http://contents-virtual.eventxtra.com/uploads/emsd831_production/originals/bda2d43b-5503-4300-a278-bdfe056d7005.jpeg'],
    [[Apps.VirtualPressCon], 'https://contents-virtual.eventxtra.com/uploads/xtra_staging/originals/90ceb449-28e8-458a-a3af-3f82a8545afe.png'],
    [Apps.HKSTP608, BackgroundImgDefault],
    [Apps.Jetro817, Background817],
    [Apps.Uzabase826, 'https://contents-virtual.eventxtra.com/uploads/uzabase826_production/originals/c0c5808f-6c6d-4c49-92e6-b9ee5c4bdc45.png'],
    [Apps.Eventage824, 'https://contents-virtual.eventxtra.com/uploads/eventage_staging/originals/829cb56d-c9c7-4a83-8684-30df2af0584e.jpg'],
  ], '');

  const backgroundVideoSrc = envSwitch([
    [Apps.PicoX707, 'https://contents-virtual.eventxtra.com/uploads/vxevents_staging/originals/eba43b18-9d7d-494a-9aa4-0f4039b2339a.mp4'],
    [Apps.Uzabase826, 'https://contents-virtual.eventxtra.com/uploads/uzabase826_production/originals/c98945d7-bbfe-4147-a170-0daf37477557.mp4'],
  ], null);

  const { showChatWindow } = useContext(ChatContext);
  const openChatWindow = () => {
    window.trackingEvent('OpenChatWindow', 'HallPage', 'BoothList');
    // show conversation list
    showChatWindow('', '', '');
  };

  useEffect(() => {
    window.objectFitPolyfill();
    window.trackingEvent('MainMenu_Hall', 'Enter', '');
  }, []);

  const {
    selectedFilter,
    setSelectedFilter,
    subLevelOnClick,
    onClick,
    resetSelectedFilter,
  } = useFilter();

  const fetchingExhibitors = useSelector(selectors.fetching);
  const exhibitors = useSelector(selectors.filterByKeywords(selectedFilter));
  const filterOptions = useSelector(selectors.getFilterOptions());
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const boothIds = useSelector(selectors.boothIds);

  const hasFilter = useMemo(
    () => (filterOptions?.length > 1 || Object.keys(filterOptions).length > 1),
    [filterOptions, filterOptions?.length],
  );

  const needChatroomList = APP !== 'hkstp608';

  const pageTitle = PAGE_NAMES.hall || t('page.hall', 'Exhibition Hall');
  const eventName = useMemo(() => {
    if (reedApps.includes(APP)) return t('common:title', EVENT_NAME);
    return EVENT_NAME;
  }, [t]);

  return (
    <RootContainer backgroundImg={backgroundImgSrc}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${pageTitle} | ${eventName}`}</title>
        <meta name="description" content={`${pageTitle} page of ${eventName}.`} />
      </Helmet>
      {
        backgroundVideoSrc && (
          <BackgroundVideo autoPlay loop muted playsInline data-object-fit="cover">
            <source src={backgroundVideoSrc} type="video/mp4" />
          </BackgroundVideo>
        )
      }
      {
        APP === 'ms624' && isDesktop && (
          <Background624Container />
        )
      }
      {
        // 801 chat room hide icon after live day
        ![Apps.Jetro817, 'ms624', 'polyu618', Apps.Uzabase826].includes(APP)
        && !isEmpty(boothIds)
        && !(isDesktop && needChatroomList)
        && !isChatroomDisabledByReed()
        && !isChatroomDisabled()
        && (
          <PopupBtn onClick={openChatWindow}>
            <img src={ChatroomIcon} alt="chatroom" />
          </PopupBtn>
        )
      }
      <BoothFilterListContainer>
        {hasFilter && (
          <BoothFilterSwitch
            filterOptions={filterOptions}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            resetSelectedFilter={resetSelectedFilter}
            numOfExhibitors={exhibitors.length}
            subLevelOnClick={subLevelOnClick}
            onClick={onClick}
          />
        )}
        <BoothListContainer>
          <BoothListWrapper>
            {!fetchingExhibitors ? (
              <HallListComponent
                exhibitors={exhibitors}
                isFiltered={!isEmpty(selectedFilter)}
                isDesktop={isDesktop}
                hasFilter={hasFilter}
              />
            ) : (
              <CircularLoadingIndicator />
            )}
          </BoothListWrapper>
        </BoothListContainer>
      </BoothFilterListContainer>
    </RootContainer>
  );
};

export default HallPage;
