import { useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import Apps, { reedApps } from 'apps';
import {
  RONGCLOUD_CHATROOM_PREFIX,
  APP, ENV,
  ENABLED_FEATURES,
} from 'appenv';
import { UserSessionContext } from 'components/UserSession';
import groupChatJoin from 'network/groupChatJoin';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import isChatroomDisabledByReed from 'components/chat/utils/isChatroomDisabledByReed';
import ChatContext from 'components/chat/ChatContext';

declare global {
  interface Window {
    RongIMLib: any;
    RongIMClient: any;
  }
}

type useAutoJoinMsgDataRef = {
  authToken: string;
  name: string;
  tag: string;
  description: string;
  dispatch: Dispatch;
  t: TFunction;
  isEnabledAutoMsg: () => boolean;
}

const useAutoJoinMsg = (boothId: string) => {
  const translation = useTranslation();
  const { userSession } = useContext(UserSessionContext);
  const { status, sendMessage } = useContext(ChatContext);
  const dispatcher = useDispatch();
  const ref = useRef<useAutoJoinMsgDataRef>(null);
  ref.current = {
    authToken: userSession?.authToken,
    name: userSession?.name,
    // eslint-disable-next-line camelcase
    tag: userSession?.customAttendeeFields?.vexpo_chat_tag,
    // eslint-disable-next-line camelcase
    description: userSession?.customAttendeeFields?.vexpo_chat_user_description,
    dispatch: dispatcher,
    t: translation.t,
    isEnabledAutoMsg: () => {
      if (userSession?.role !== 'attendee') {
        return false;
      } // scmp711 only

      if (reedApps.includes(APP as any)) return !isChatroomDisabledByReed();

      switch (APP) {
        case Apps.Jetro817:
        case 'hkstp608':
        case 'paloalto623':
        case 'ms624':
        case 'picox707':
          return false;
        default:
          return true;
      }
    },
  };

  useEffect(() => {
    if (!boothId) return;

    async function sendAutoJoinMsg(groupId: string) {
      const {
        authToken, t,
      } = ref.current;
      const success = await groupChatJoin(authToken, groupId);
      if (success && status === 'connected') {
        const conversationType = window.RongIMLib.ConversationType.GROUP;
        sendMessage(groupId, conversationType, t('chat.has_joined'), 'TextMessage');
      }
    }

    if (ref.current.isEnabledAutoMsg()) {
      const chatroomId = ENABLED_FEATURES.xtraCMS ? `${ENV}_${boothId}` : `${RONGCLOUD_CHATROOM_PREFIX}_booth_${boothId}`;
      sendAutoJoinMsg(chatroomId);
    }
  }, [boothId]);
};

export default useAutoJoinMsg;
