import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import deskFront from 'assets/booths/desk-front.png';
import deskShadow from 'assets/booths/desk-shadow.png';
import deskCover from 'assets/booths/desk-cover.svg';
import GenericCMSBoothBanner from '../GenericCMSBoothBanner';

const DeskUpon = styled.div`
  position: absolute;
  width: 440px;
  height: 250px;
`;

const DeskBottom = styled.div`
  position: absolute;
  width: 440px;
  height: 250px;
`;

const DeskFrontImg = styled.img`
  position: absolute;
  left: 32px;
  height: 222px;
  width: 366px;
  top: 16px;
`;

const LogoContainer = styled.div`
  position: absolute;
  width: 290px;
  height: 118px;
  top: 72px;
  left: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const StyledImg = styled.img`
  flex: 0 0 auto;
  max-width: 100%;
  max-height: 100%;
`;

const DeskShadowContainer = styled.div`
  position: absolute;
  top: 190px;
  width: 480px;
  height: 70px;
  left: -25px;
`;

const DeskCoverImage = styled.img`
  position: relative;
  bottom: 255px;
`;

const LogoDesk = ({
  deskColor,
  logo,
}) => (
  <>
    <LogoContainer>
      <GenericCMSBoothBanner
        bannerData={logo}
      />
    </LogoContainer>
    <DeskBottom>
      <svg width="431" height="252" viewBox="0 0 431 252" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M397.639 29.2139V229.914C397.839 237.014 378.139 244.314 346.439 246.614C303.339 249.714 259.239 251.314 215.139 251.314C171.039 251.314 126.939 249.714 83.8387 246.614C52.1387 244.314 32.5387 237.014 32.6387 229.914V29.2139C32.4387 36.3139 52.1387 43.6139 83.8387 45.9139C126.939 49.0139 171.039 50.6139 215.139 50.6139C259.239 50.6139 303.339 49.0139 346.439 45.9139C378.139 43.5139 397.839 36.2139 397.639 29.2139Z" fill={deskColor || '#695E8A'} />
      </svg>
    </DeskBottom>
    <DeskShadowContainer>
      <StyledImg src={deskShadow} />
    </DeskShadowContainer>
    <DeskFrontImg src={deskFront} />
    <DeskUpon>
      <svg width="431" height="252" viewBox="0 0 431 252" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M426.139 12.8138C414.639 4.91384 386.639 0.413879 357.639 0.413879C351.139 0.413879 344.639 0.613892 338.239 1.11389C297.839 4.01389 256.539 5.51385 215.239 5.51385C173.939 5.51385 132.639 4.01389 92.2387 1.11389C85.8387 0.613892 79.3387 0.413879 72.8387 0.413879C43.8387 0.413879 15.8387 4.91384 4.33869 12.8138C1.73869 14.6138 0.438685 16.5139 0.338685 18.4139V29.5139C0.138685 37.8139 23.3387 46.4139 60.6387 49.1139C111.339 52.8139 163.339 54.7139 215.239 54.7139C267.139 54.7139 319.039 52.8139 369.839 49.1139C407.139 46.4139 430.339 37.8139 430.139 29.5139V18.4139C430.039 16.5139 428.739 14.6138 426.139 12.8138Z" fill={deskColor || '#695E8A'} />
      </svg>
      <DeskCoverImage src={deskCover} alt="Desk Cover" />
    </DeskUpon>
  </>
);

LogoDesk.propTypes = {
  deskColor: PropTypes.string.isRequired,
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
};

LogoDesk.defaultProps = {
  logo: '',
};

export default LogoDesk;
