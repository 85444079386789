import { apiUrl, eventToken } from 'network/common/cmsConfig';
import request, { gql } from 'graphql-request';
import {
  PreviewBooth,
  CommonLocalizableString, CommonLocalizableImage,
  CommonLocalizableVideo, CommonLocalizableFile,
} from 'network/common/cmsFragment';

const fetchPreviewBoothsQuery = `
  ${CommonLocalizableString}
  ${CommonLocalizableImage}
  ${CommonLocalizableVideo}
  ${CommonLocalizableFile}
  ${PreviewBooth}

  query fetchPreviewBoothsQuery($eventToken: String!, $locales: [Locale!]!, $boothCount: Int, $boothsCursor: String) {
    data: eventByToken(eventToken: $eventToken) {
      booths(first: $boothCount, after: $boothsCursor) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...PreviewBooth
        }
      }
    }
  }
`;

export interface FetchPreviewBoothsRequestProps {
  locales?: string[];
  boothsCursor?: string;
  boothCount?: number;
}

export const fetchPreviewBoothsRequest = ({
  locales = ['en'],
  boothCount = 2,
  boothsCursor,
}: FetchPreviewBoothsRequestProps = {}) => request(
  apiUrl,
  fetchPreviewBoothsQuery,
  {
    eventToken,
    locales,
    boothCount,
    boothsCursor,
  },
).then((response) => response.data);

const fetchMetaBoothsQuery = `
  ${CommonLocalizableString}

  query fetchPreviewBoothsQuery($eventToken: String!, $locales: [Locale!]!, $boothCount: Int, $boothsCursor: String) {
    data: eventByToken(eventToken: $eventToken) {
      booths(first: $boothCount, after: $boothsCursor) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          id
          name {
            ...CommonLocalizableString
          }
        }
      }
    }
  }
`;

export interface FetchMetaBoothsRequestProps {
  locales?: string[];
  boothsCursor?: string;
  boothCount?: number;
}

export const fetchMetaBoothsRequest = ({
  locales = ['en'],
  boothCount = 15,
  boothsCursor,
}: FetchMetaBoothsRequestProps = {}) => request(
  apiUrl,
  fetchMetaBoothsQuery,
  {
    eventToken,
    locales,
    boothCount,
    boothsCursor,
  },
).then((response) => response.data);

const fetchBoothCategoriesByIdQuery = gql`
  ${CommonLocalizableString}

  query fetchBoothCategoriesByIdQuery($boothId: ID!, $locales: [Locale!]!, $cursor: String) {
    data: node(id: $boothId) {
      ... on Booth {
        id
        categories(after: $cursor) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            id
            name {
              ...CommonLocalizableString
            }
          }
        }
      }
    }
  }
`;

export const fetchBoothCategoriesByIdRequest = ({
  locales = ['en'],
  boothId,
  cursor,
}: any = {}) => request(
  apiUrl,
  fetchBoothCategoriesByIdQuery,
  {
    locales,
    boothId,
    cursor,
  },
).then((response) => response.data);

const fetchBoothCategoriesByIdsQuery = gql`
  ${CommonLocalizableString}

  query fetchBoothCategoriesByIdsQuery($boothIds: [ID!]!, $locales: [Locale!]!) {
    data: nodes(ids: $boothIds) {
      ... on Booth {
        id
        categories {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            id
            name {
              ...CommonLocalizableString
            }
          }
        }
      }
    }
  }
`;

export const fetchBoothCategoriesByIdsRequest = ({
  locales = ['en'],
  boothIds,
}: any = {}) => request(
  apiUrl,
  fetchBoothCategoriesByIdsQuery,
  {
    locales,
    boothIds,
  },
).then((response) => response.data);

const fetchBoothDesignElementsByIdQuery = gql`
  ${CommonLocalizableString}
  ${CommonLocalizableVideo}
  ${CommonLocalizableImage}
  ${CommonLocalizableFile}

  query fetchBoothDesignElementsByIdQuery(
    $boothId: ID!
    $locales: [Locale!]!
    $count: Int
    $cursor: String
  ) {
    data: node(id: $boothId) {
      ... on Booth {
        id
        appliedDesignCodename
        description {
          ...CommonLocalizableString
        }
        designElements(first: $count, after: $cursor) {
          nodes {
            id
            name
            kind
            color
            image {
              ...CommonLocalizableImage
            }
            video {
              ...CommonLocalizableVideo
            }
            contentRichtextHtml {
              ...CommonLocalizableString
            }
            thumbnailImage {
              ...CommonLocalizableImage
            }
            pdf {
              ...CommonLocalizableFile
            }
          }
        }
      }
    }
  }
`;

export const fetchBoothDesignElementsByIdRequest = ({
  locales = ['en'],
  boothId,
  count = 30,
  cursor,
}: any = {}) => request(
  apiUrl,
  fetchBoothDesignElementsByIdQuery,
  {
    boothId,
    locales,
    count,
    cursor,
  },
).then((response) => response.data);
