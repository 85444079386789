import React, { Suspense, lazy } from 'react';
import Apps, { reedApps } from 'apps';
import { APP, EVENT_NAME } from 'appenv';
import { Helmet } from 'react-helmet';

const LandingTemplate = lazy(() => {
  if (reedApps.includes(APP)) {
    return import('./templates/Landing902Template');
  }

  switch (APP) {
    case 'polyu618':
      return import('./templates/Landing618Template');
    case 'paloalto623':
      return import('./templates/Landing623Template');
    case 'ms624':
      return import('./templates/Landing624Template');
    case 'sph706':
      return import('./templates/Landing706Template');
    case 'scmp711':
      return import('./templates/Landing711Template');
    case 'lighthouse710':
      return import('./templates/Landing710Template');
    case 'hkstp608':
      return import('./templates/Landing608Template');
    case Apps.Neoventure1012:
      return import('./templates/Landing1012NeoventureTemplate');
    case Apps.Jetro817:
      return import('./templates/Landing817Template');
    case Apps.Uzabase826:
      return import('./templates/Landing826Template');
    case Apps.Mig0828:
      return import('./templates/Landing828Template');
    case Apps.Emsd831:
      return import('./templates/Landing831Template');
    case Apps.Sph818:
      return import('./templates/Landing818Template');
    case Apps.Cuhk925:
      return import('./templates/Landing925Template');
    case Apps.Eventage824:
      return import('./templates/Landing824Template');
    default:
      return import('./templates/Landing707Template');
  }
});

const LandingTemplateSwitch = () => (
  <Suspense fallback={null}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{EVENT_NAME}</title>
      <meta name="description" content={`Landing page of ${EVENT_NAME}.`} />
    </Helmet>
    <LandingTemplate />
  </Suspense>
);

export default LandingTemplateSwitch;
